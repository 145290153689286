export default {
  translations: {
    language: {
      message: 'zh-Hant',
    },
    welcomeToOurDigitalMenu: {
      message: '歡迎來到我們的數字菜單',
    },
    beWelcome: {
      message: '歡迎',
    },
    hello: {
      message: '你好',
    },
    comments: {
      message: '註釋',
    },
    selectUntil: {
      message: '選擇直到',
    },
    optionsYouSelected: {
      message: ' 選項。 你選擇了',
    },
    youMustAddAllRequiredComplements: {
      message: '您必須添加所有必需的附加組件。',
    },
    youMustQuantityRequired: {
      message: '您必須添加至少 1 種產品。',
    },
    myOrder: {
      message: '我的請求',
    },
    emptyCart: {
      message: '空購物車',
    },
    addMoreItems: {
      message: '添加更多項目',
    },
    subtotal: {
      message: '小計',
    },
    total: {
      message: '全部的',
    },
    formOfPayment: {
      message: '付款方式',
    },
    deliveryFee: {
      message: '送貨費',
    },
    deliveryIn: {
      message: '交貨',
    },
    sendOrder: {
      message: '發送請求',
    },
    ourResponseTimeIsUpTo: {
      message: '我們的響應時間最長 ',
    },
    minutes: {
      message: '分鐘',
    },
    back: {
      message: '回來',
    },
    backToMenu: {
      message: '返回菜單',
    },
    confirmAddress: {
      message: '確認地址',
    },
    confirmTakAway: {
      message: '確認退出',
    },
    invalidCoupon: {
      message: '優惠券無效。',
    },
    discountApplied: {
      message: '已申請折扣.',
    },
    discountCoupons: {
      message: '優惠券'
    },
    youMustAskForChangeForAnAmountGreaterThan: {
      message: '您必須要求找零金額大於',
    },
    checkAllFields: {
      message: '檢查所有字段。',
    },
    pleaseSelectAProductBeforeProceeding: {
      message: '請在繼續之前選擇產品',
    },
    selectAPaymentMethod: {
      message: '選擇付款方式',
    },
    chooseOneOfTheOptions: {
      message: '選擇其中一個選項',
    },
    catalog: {
      message: '目錄',
    },
    deliveryTakeAway: {
      message: '交貨 | 退出',
    },
    localConsumption: {
      message: '本地消費',
    },
    simpleEasyFastDigital: {
      message: '簡單的。 簡單的。 快速地。 數字的。',
    },
    imagesAreForIllustrationPurposesOnly: {
      message: '圖像是僅用於說明目的。',
    },
    changeFor: {
      message: '為。。改變',
    },
    productImage: {
      message: '產品圖片',
    },
    weAreNotOpenYet: {
      message: '我們還沒開門s',
    },
    checkOur: {
      message: '看看我們的',
    },
    openingHours: {
      message: '營業時間',
    },
    accessMenu: {
      message: '訪問菜單',
    },
    oops: {
      message: '哎呀',
    },
    browse: {
      message: '導航',
    },
    theHighlights: {
      message: '由亮點',
    },
    helloHowAreYou: {
      message: '你好嗎？',
    },
    forNowOurMenuCanOnlyBeAccessedOnMobilePhones: {
      message: '目前，我們的菜單只能在手機上訪問。',
    },
    weAreAlreadyWorkingToBringYouTheBestOnlineMenuExperienceAccessibleFromAnyDevice: {
      message: '我們已經在努力提供可從任何設備訪問的最佳在線菜單體驗。',
    },
    identification: {
      message: '鑑別',
    },
    mandatoryFilling: {
      message: '強制填寫',
    },
    name: {
      message: '姓名',
    },
    phone: {
      message: '電話',
    },
    cityTakeAway: {
      message: '城市/提款',
    },
    street: {
      message: '路',
    },
    cartQuantity: {
      message: '數量',
    },
    cartItem: {
      message: '物品',
    },
    cartValue: {
      message: '價值',
    },
    neighborhoods: {
      message: '社區',
    },
    number: {
      message: '數字',
    },
    complement: {
      message: '補充',
    },
    reference: {
      message: '參考',
    },
    coupon: {
      message: '優惠券',
    },
    add: {
      message: '添加',
    },
    typeWhatYouAreLookingFor: {
      message: '輸入您要查找的內容...',
    },
    goodThatYouAreHere: {
      message: '很高興你在這裡',
    },
    chooseTheItemYouWantAnd: {
      message: '選擇您想要的項目並',
    },
    toPlaceYourOrder: {
      message: '下訂單',
    },
    callAnAttendant: {
      message: '呼叫服務員',
    },
    SUN: {
      message: '週日',
    },
    MON: {
      message: '週一',
    },
    TUES: {
      message: '週二',
    },
    WED: {
      message: '週三',
    },
    THU: {
      message: '週四',
    },
    FRI: {
      message: '週五',
    },
    SAT: {
      message: '週六',
    },
    from: {
      message: "從"
    },
    to: {
      message: "至"
    },
    hum: {
      message: '嗯',
    },
    nothave: {
      message: "看起來我們還沒有"
    },
    ismenu: {
      message: "它在我們的菜單上..."
    },
    Additional: {
      message: "額外的"
    },
    takeAway: {
      message: "帶走"
    },
  },
};
