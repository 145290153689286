import React from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { List, ListItem, Arrow, FoodImg } from "./CategoryListStyles";
import ArrowIcon from "../../assets/img/ic-forward.svg";
import TranslatorFunction from "../I18n/Translator";

const CategoryList = ({ categories }) => {

  const { domain, servicemode } = useParams();
  const language = TranslatorFunction({ path: 'language.message' });
  return (
    <List>
      {categories.map((category, index) => (
        <Link
        
          to={{
            pathname: `/${domain}/${servicemode}/${encodeURIComponent(
              category.translatedName[0] === undefined? category.name  : category.translatedName[0].name[0][language]
            )}`,
            state: { category },
          }}
          key={category._id}
        >
          <ListItem index={index}>
            <p>{category.translatedName[0] === undefined ? category.name  : (category.translatedName[0].name[0][language] !== undefined ? category.translatedName[0].name[0][language] : category.name )}</p>
            <FoodImg img={category.imageURL} />
            <Arrow secondary={category.imageURL} src={ArrowIcon} />
          </ListItem>
        </Link>
      ))}
    </List>
  );
};

CategoryList.defaultProps = {
  categories: [],
};

CategoryList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

export default CategoryList;
