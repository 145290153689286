import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Badge } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import {
  MainColorIcon,
  HeaderContainer,
  FantasyName,
  ContainerIcons,
  ContentIcons,
} from './HeaderStyles';
import logoHeader from '../../assets/img/logo_horizontal.svg';
// import menuHeader from "../../assets/img/menuHeader.svg";
import searchIcon from '../../assets/img/searchIcon.svg';
import backIcon from '../../assets/img/ic-back.svg';
import cartIcon from '../../assets/img/cart.svg';
import useDomain from '../../hooks/useDomain';
import restMenus from '../../api/menus/rest-menus';
import {
  getModeOperation,
  getOperationId,
  setFantasyName as FantasyNameStorage,
} from '../../services/localStorage';
import { CartContext } from '../../hooks/CartContext';
import I18n from '../../components/I18n';
import useOperation from '../../hooks/useOperation';
import './style.css'
const Header = () => {
  const [cart] = useContext(CartContext);
  const [isHome, setIsHome] = useState();
  const [isSearch, setIsSearch] = useState();
  const [fantasyName, setFantasyName] = useState('');
  const isDelivery = getModeOperation() === 'true';
  const location = useLocation();
  const { pathname } = location;

  const history = useHistory();
  const domain = useDomain();
  const operation = useOperation();
  const [, , isOrder, isMenu] = pathname.split('/');

  const isLocal = isOrder === 'local' && isMenu === undefined;

  const shadow = useMemo(() => {
    if (isOrder === 'order') return true;
    if (isMenu !== 'closed' && isMenu !== undefined) return true;
    return false;
  }, [isMenu, isOrder]);

  const searchURL = `${domain}/${operation}/search`;

  const enableIn = getOperationId();

  const handleMenusResponse = (response) => {
    const { fantasyName: fantasyNameResponse } = response.data;
    FantasyNameStorage(fantasyNameResponse);
    setFantasyName(fantasyNameResponse);
  };

  const fetchFantasyName = () =>
    restMenus.fetchMenus(domain, enableIn).then(handleMenusResponse);

  useEffect(() => {
    setIsHome(location.pathname === `/${domain}`);
    setIsSearch(location.pathname === '/search');
    fetchFantasyName();
    // eslint-disable-next-line
  }, [location, domain]);

  const renderIcons = () => {
    const searchDisabled = location.pathname.split('/').pop() === 'search' ? true : false;
    const orderDisabled = location.pathname.split('/').pop() === 'order' ? true : false;
    if (isMenu === 'closed' || (isOrder === 'local' && isMenu !== 'menu')) {
      return <div />;
    }
    if (isDelivery) {
      return (
        <ContainerIcons>
          <ContentIcons isDelivery>
            {orderDisabled ? (
              <div>
                <Badge badgeContent={cart?.products?.length} color="secondary">
                  <ReactSVG src={cartIcon} />
                </Badge>
              </div>
            ) : (
              <Link to={`/${domain}/order`}>
                <Badge badgeContent={cart?.products?.length} color="secondary">
                  <ReactSVG src={cartIcon} />
                </Badge>
              </Link>
            )}
            {pathname !== `/${domain}` ? (
              searchDisabled ?
                <div>
                  <MainColorIcon src={searchIcon} />
                </div>
                :
                <Link to={`/${searchURL}`}>
                  <MainColorIcon src={searchIcon} />
                </Link>
            ) : (
              <div />
            )}
          </ContentIcons>
        </ContainerIcons>
      );
    }
    if (!isDelivery) {
      return (
        <ContainerIcons>
          {pathname !== `/${domain}` ? (
            searchDisabled ?
              <div>
                <MainColorIcon src={searchIcon} />
              </div>
              :
              <Link to={`/${searchURL}`}>
                <MainColorIcon src={searchIcon} />
              </Link>
          ) : (
            <div />
          )}
        </ContainerIcons>
      );
    }
  };

  const renderBack = () => {
    if (isLocal || isMenu === 'closed') return <div />;
    if (!isHome) {
      return (
        <div className='contener1'>
          <MainColorIcon src={backIcon} onClick={() => history.goBack()} />
          <div style={{ paddingRight: '1%' }} />
        </div>
      );
    }
  };

  return (
    <HeaderContainer
      boxShadow={!isSearch}
      isHome={isHome}
      haveShadow={shadow}
      isLocal={isLocal}
    >

      <div>
        {renderBack()}
        <I18n />
      </div>
      <div>
        <Link to={`/${domain}`}>
          {fantasyName ? (
            fantasyName.length > 9 && !isHome && isMenu !== 'closed' ? (
              <FantasyName isHome={isHome}>{`${fantasyName.slice(
                0,
                9
              )}...`}</FantasyName>
            ) : (
              <FantasyName isHome={isHome}>{fantasyName}</FantasyName>
            )
          ) : (
            <ReactSVG src={logoHeader} />
          )}
        </Link>
      </div>

      {!isHome && renderIcons()}
    </HeaderContainer>
  );
};

export default Header;
