import styled from "styled-components";
import BackgroundImg from "../../assets/img/webDisclaimerBackground-min.png";



const Background = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-blend-mode: overlay;
  background: url(${BackgroundImg}), rgba(26, 26, 26);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
const I18NDIV = styled.div`
position: absolute;
  right: 50px;
  top: 0px;
`;

const ContentContainer = styled.div`
  width: 545px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 118px;
    height: 148px;
  }
`;

const Title = styled.h1`
  margin-top: 48px;
  margin-bottom: 120px;
  font-weight: 300;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  color: ${({ theme }) => theme.colors.textColorContrast};
`;

const StatusNeutralColorBar = styled.div`
  width: 88px;
  height: 1px;
  margin-bottom: 20px;
  background: ${({ theme }) => theme.colors.statusNeutral};
`;

const Paragraph = styled.p`
  width: 448px;
  font-weight: 300;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: ${({ theme }) => theme.colors.textColorContrast};
`;

export {
  Background,
  ContentContainer,
  Title,
  Paragraph,
  StatusNeutralColorBar,
  I18NDIV,
};
