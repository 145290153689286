import styled from "styled-components";

export const Container = styled.div`
  input {
    color: #1a1a1a;
    font-size: 14px;
  }

  .MuiTextField-root {
    width: 100% !important;
  }

  .MuiFormLabel-root,
  .MuiFormLabel-root span {
    font-size: 14px;

    color: #ff5533 !important;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid #ff5533;
  }
  .MuiInput-underline:after {
    border-bottom: 2px solid #ff5533;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #ff5533;
  }
`;
