import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import formatPrice from '../../utils';
import {
  List,
  ListItem,
  FoodImg,
  ContentContainer,
  Arrow,
  PriceText,
} from './ProductsListStyles';
import ArrowIcon from '../../assets/img/ic-forward.svg';

const renderListItem = (product, index, categoryName, isSearch, language) => {
  const { _id, name, shortDescription, value, imageURL, subcategories } =
    product;  
  const nameEnconded = encodeURIComponent(name);

  const link = isSearch
    ? `${categoryName}/${subcategories[0].name}/${nameEnconded}`
    : `${categoryName}/${nameEnconded}`;

  
  let fileURL = [];

  
  if (Array.isArray(imageURL) && imageURL.length > 0) {
    fileURL = imageURL[0];
  }
  
  if (!Array.isArray(imageURL)) {
    fileURL = [imageURL];
  }
  

  if (Array.isArray(imageURL) && imageURL.length === 0) {
    fileURL = null;
  }

  return (
    <Link to={{ pathname: link, state: { product } }} key={`${_id}${index}`}>
      <ListItem index={index}>
        <ContentContainer>
          <h2>{name}</h2>     
          <p>{ product.description[0]?.shortDescription ? ( product.description[0].shortDescription[0][language] !== undefined? product.description[0].shortDescription[0][language] : shortDescription): shortDescription}</p>
                {value !== 0 ? (
            <PriceText hasImg={fileURL}>{formatPrice(value)}</PriceText>
          ) : (
            ''
          )}
          
        </ContentContainer>
        
        <FoodImg img={fileURL} />
        
        <Arrow secondary={fileURL} src={ArrowIcon} />
        
      </ListItem>
    </Link>
  );
};

const ProductsList = ({ products, categoryName, isSearch,language }) => {
  return (
    <List>
      {products.map((product, index) =>
        renderListItem(product, index, categoryName, isSearch,language)
      )}
    </List>
  );
};
ProductsList.defaultProps = {
  products: [],
  categoryName: '',
  language:'',
  isSearch: false,
};

ProductsList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  categoryName: PropTypes.string,
  language: PropTypes.string,
  isSearch: PropTypes.bool,
};

export default ProductsList;
