/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import InputText from "../../components/inputs/Input";
import { getTakeaway } from "../../services/localStorage";
import Accordion from "./components/Accordion";
import {
  Container,
  ContainerTable,
  ContainerTitles,
  Form,
  InputContainer,
  InputGroupContainer,
} from "./styles";

import TranslatorFunction from "../../components/I18n/Translator"

function Identification(props) {
  const {
    dataAPI,
    dataForm,
    cities,
    handleChangeInput,
    handleChangeCity,
    filteredNeighborhood,
    handleSelectNeighborhood,
    selectedValues,
    validateCoupon,
  } = props;

  const takeAway = TranslatorFunction({ path: 'takeAway.message' });
  useEffect(() => {
    if (cities.length > 0) {
      if (!!getTakeaway()) {
        cities.unshift({ name: takeAway, id: getTakeaway() });
      }
    }
  }, [cities]);

  const identification = TranslatorFunction({ path: 'identification.message' });
  const mandatoryFilling = TranslatorFunction({ path: 'mandatoryFilling.message' });
  const name = TranslatorFunction({ path: 'name.message' });
  const phone = TranslatorFunction({ path: 'phone.message' });
  const cityTakeAway = TranslatorFunction({ path: 'cityTakeAway.message' });
  const street = TranslatorFunction({ path: 'street.message' });
  const neighborhoods = TranslatorFunction({ path: 'neighborhoods.message' });
  const number = TranslatorFunction({ path: 'number.message' });
  const complement = TranslatorFunction({ path: 'complement.message' });
  const reference = TranslatorFunction({ path: 'reference.message' });
  const coupon = TranslatorFunction({ path: 'coupon.message' });

  return (
    <Container>
      <ContainerTitles>
        <h1>
          {identification}
        </h1>
        <h3>
          <b>*</b> 
          {mandatoryFilling}
        </h3>
      </ContainerTitles>
      <Form>
        {dataAPI?.mandatory?.name && (
          <InputContainer>
            <InputText
              id={"Name"}
              label={name}
              required={!!dataAPI?.mandatory?.name}
              value={dataForm.clientName}
              onChange={(value) => handleChangeInput("clientName", value)}
            />
          </InputContainer>
        )}
        {dataAPI?.mandatory?.cpf && (
          <InputContainer>
            <InputText
              id={"CPF"}
              label={"CPF"}
              required={!!dataAPI?.mandatory?.cpf}
              value={dataForm?.cpf}
              onChange={(value) => handleChangeInput("cpf", value)}
            />
          </InputContainer>
        )}
        {dataAPI?.mandatory?.phone && (
          <InputContainer>
            <InputText
              id={"Telefone"}
              label={phone}
              required={!!dataAPI?.mandatory?.phone}
              value={dataForm?.phone}
              onChange={(value) => handleChangeInput("phone", value)}
            />
          </InputContainer>
        )}
        {dataAPI?.mandatory?.cep && (
          <InputContainer>
            <InputText
              id={"CEP"}
              label={"CEP"}
              required={dataAPI?.mandatory?.cep}
              value={dataForm?.address?.cep}
              onChange={(value) => handleChangeInput("cep", value)}
            />
          </InputContainer>
        )}

        <Accordion
          title={cityTakeAway}
          data={cities}
          name="cities"
          onChange={handleChangeCity}
          selectedValue={selectedValues.cityName}
        />
        {dataForm?.address.city !== takeAway && (
          <Accordion
            title={neighborhoods}
            data={filteredNeighborhood}
            name="neighborhood"
            onChange={handleSelectNeighborhood}
          />
        )}
        {dataAPI?.mandatory.address &&
          dataForm?.address.city !== takeAway && (
            <>
              <InputContainer>
                <InputText
                  id={"Rua"}
                  label={street}
                  required={!!dataAPI?.mandatory.address}
                  value={dataForm?.address.street}
                  onChange={(value) => handleChangeInput("street", value)}
                />
              </InputContainer>
              <InputGroupContainer>
                <InputText
                  id={"Numero"}
                  label={number}
                  required={!!dataAPI?.mandatory.address}
                  value={dataForm?.address.street_number}
                  onChange={(value) =>
                    handleChangeInput("street_number", value)
                  }
                />
                <InputText
                  id={"Complemento"}
                  label={complement}
                  value={dataForm?.address.street_complement}
                  onChange={(value) =>
                    handleChangeInput("street_complement", value)
                  }
                />
              </InputGroupContainer>
              <InputContainer>
                <InputText
                  id={"Referencia"}
                  label={reference}
                  value={dataForm?.address.reference}
                  onChange={(value) => handleChangeInput("reference", value)}
                />
              </InputContainer>
            </>
          )}

        <ContainerTable />
        <InputContainer>
          <InputText
            label={coupon}
            id="coupon"
            onBlur={validateCoupon}
            onChange={(value) => handleChangeInput("coupon", value)}
            value={dataForm.coupon}
          />
        </InputContainer>
      </Form>

      <ContainerTable />
    </Container>
  );
}

export default Identification;
