import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import priceFormatter from '../../utils';
import { AiOutlineClose } from 'react-icons/ai';
import Badge from '../../components/badge/Badge';
import {
  InfoContainer,
  PageWrapper,
  ProductTitle,
  ProductInfo,
  PriceContainer,
  PriceTag,
  CategoryName,
  TagsContainer,
  BackHomeLink,
  FullScreenImage,
  ImageFullScreen,
} from './ProductInfoStyles';
import Carousel from '../../components/carousel/Carousel';
import TranslatorFunction from '../../components/I18n/Translator';

const ProductInfoPage = () => {
  const { category, servicemode } = useParams();
  const [product, setProduct] = useState({});
  const [fullScreen, setFullScreen] = useState(false);
  const [fileSelected, setFileSelected] = useState();
  const [filesURL, setFilesURL] = useState([]);
  const backToMenu = TranslatorFunction({ path: 'backToMenu.message' });
  const language = TranslatorFunction({ path: 'language.message' });
  const location = useLocation();
  const history = useHistory();

  const handleChangeFile = (img) => {
    setFileSelected(img);
    setFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setFullScreen(false);
    setFileSelected();
  };

  const goToMenu = () => {
    const link = location.pathname.split('/')[1];

    history.push(`/${link}/${servicemode}/menu`)
  }

  const { name, value, imageURL, longDescription, tags } = product;
  useEffect(() => {
    if (location.state && location.state.product) {
      setProduct(location.state.product);
    }
  }, [location]);

  useEffect(() => {
    let fileURL = [];

    if (Array.isArray(imageURL) && imageURL.length > 0) {
      fileURL = imageURL;
    }

    if (!Array.isArray(imageURL)) {
      fileURL = [imageURL];
    }

    if (Array.isArray(imageURL) && imageURL.length === 0) {
      fileURL = [null];
    }
    setFilesURL(fileURL);
    // eslint-disable-next-line
  }, [product]);

  const longDescriptionTranslated = Array.isArray(product.description)  ? (product?.description[0]?.longDescription[0][language] !== undefined ? product?.description[0]?.longDescription[0][language] : longDescription): longDescription;
  console.log('product', longDescriptionTranslated )

  const formattedLongDescription = React.useMemo(() => {
    const emailPattern =
      // eslint-disable-next-line
      /((https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%.\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%\+.~#?&\/\/=]*))/g;
    const test = longDescriptionTranslated?.replace(
      emailPattern,
      '<a href="$1" target="_blank" rel="noopener noreferrer" class="link-a">$1</a>'
    );
    return test;
  }, [longDescriptionTranslated]);


  return (
    <PageWrapper>
      <div>
        <Carousel
          imgsURL={filesURL}
          selectImg={handleChangeFile}
          isNullImage={!filesURL[0]}
        />
        <InfoContainer>
          {value !== 0 ? (
            <PriceContainer>
              <PriceTag>{priceFormatter(value)}</PriceTag>
            </PriceContainer>
          ) : (
            ''
          )}
          <CategoryName>{product?.subcategories ? ( product?.subcategories[0]?.category?.translatedName ? product?.subcategories[0]?.category?.translatedName[0]?.name[0][language]: decodeURIComponent(category) ) : decodeURIComponent(category)   }</CategoryName>
          <ProductTitle>{name}</ProductTitle>
          <ProductInfo
            dangerouslySetInnerHTML={{ __html: longDescriptionTranslated }}
          />
          <TagsContainer>
            {tags && tags.map((tag) => <Badge key={tag}>{tag}</Badge>)}
          </TagsContainer>
        </InfoContainer>
      </div>
      <BackHomeLink onClick={goToMenu}>
        {backToMenu}
      </BackHomeLink>
      <FullScreenImage fullScreen={fullScreen}>
        <AiOutlineClose fill="#fff" onClick={handleCloseFullScreen} size={18} />
        <ImageFullScreen img={fileSelected} />
      </FullScreenImage>
    </PageWrapper>
  );
};

export default ProductInfoPage;
