import { animateScroll as scroll } from "react-scroll";

const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export const formatPriceWithSeparato = (price) => {
  return new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2 }).format(
    price
  );
};

const formatPrice = (value) =>
  value % 1 === 0 ? `R$ ${value}` : formatter.format(value);

export const converter = (min) => {
  const hours = Math.floor(min / 60);
  const minutes = min % 60;
  const textHours = `00${hours}`.slice(-2);
  const textMin = `00${minutes}`.slice(-2);

  return `${textHours}:${textMin}`;
};
export const days = ["DOM", "SEG", "TER", "QUAR", "QUI", "SEX", "SÁB"];

export const scrollToTop = () => {
  scroll.scrollToTop();
};

export default formatPrice;
