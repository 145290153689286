/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import dayjs from 'dayjs';
import { mask, unMask } from 'remask';
import ReactPixel from 'react-facebook-pixel';
import restMenu from '../../api/menus/rest-menus';
import AccordionComponent from '../../components/AccordionComponent';
import BlockLoader from '../../components/block-loader/BlockLoader';
import Button from '../../components/button/Button';
import LinkComponent from '../../components/link/Link';
import TableCart from '../../components/table-cart';
import {
  Container,
  TitleWrapper,
  ContainerButtons,
  ContainerSubtotal,
  ContainerPayment,
  ContainerButtonsFooter,
  DeliveryIn,
  ContainerResults,
  ResponseTime,
  LinkStyled,
} from './styles';
import {
  setCartStorage,
  getCartStorage,
  getFantasyName,
  getOperationId,
  getTakeaway,
} from '../../services/localStorage';
import { currencyMask, removeCurrencyMask } from '../../utils/masks';
import Identification from '../Identification';
import { CartContext } from '../../hooks/CartContext';

import {
  cepMask,
  cpfMask,
  onlyNumbers,
  removeMaskPhone,
} from '../../utils/masks';
import { orderAlphabetical } from '../../utils/order-functions';
import { formatPriceWithSeparato, scrollToTop } from '../../utils';
import TranslatorFunction from '../../components/I18n/Translator';

const genRandomIdHash = () => Math.random().toString(36).substr(2, 5);

function Cart() {
  const { pathname } = useLocation();
  const loadCartStorage = getCartStorage();
  const [cart, setCart] = useContext(CartContext);

  const [payment, setPayment] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [encodedText, setEncodedText] = useState('');
  const [officePhone, setOfficePhone] = useState('');
  const [open, setOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [nameError, setNameError] = useState('');
  const [dataAPI, setDataAPI] = useState({
    cities: [],
    neighborhood: [],
    mandatory: {
      cep: false,
      name: '',
      phone: false,
      address: false,
      cpf: false,
    },
    address: {
      state: '',
      street: '',
      street_complement: '',
      street_number: false,
    },
    deliverySettings: {
      freeShipping: 0,
      minValue: 0
    }
  });

  const myOrder = TranslatorFunction({ path: 'myOrder.message' });
  const emptyCart = TranslatorFunction({ path: 'emptyCart.message' });
  const addMoreItems = TranslatorFunction({ path: 'addMoreItems.message' });
  const subtotalTranslate = TranslatorFunction({ path: 'subtotal.message' });
  const totalTranslate = TranslatorFunction({ path: 'total.message' });
  const formOfPayment = TranslatorFunction({ path: 'formOfPayment.message' });
  const deliveryFee = TranslatorFunction({ path: 'deliveryFee.message' });
  const deliveryInTranslate = TranslatorFunction({ path: 'deliveryIn.message' });
  const sendOrder = TranslatorFunction({ path: 'sendOrder.message' });
  const ourResponseTimeIsUpTo = TranslatorFunction({ path: 'ourResponseTimeIsUpTo.message' });
  const minutes = TranslatorFunction({ path: 'minutes.message' });
  const backToMenu = TranslatorFunction({ path: 'backToMenu.message' });
  const confirmAddress = TranslatorFunction({ path: 'confirmAddress.message' });
  const confirmTakAway = TranslatorFunction({ path: 'confirmTakAway.message' });
  const invalidCoupon = TranslatorFunction({ path: 'invalidCoupon.message' });
  const discountApplied = TranslatorFunction({ path: 'discountApplied.message' });
  const youMustAskForChangeForAnAmountGreaterThan = TranslatorFunction({ path: 'youMustAskForChangeForAnAmountGreaterThan.message' });
  const checkAllFields = TranslatorFunction({ path: 'checkAllFields.message' });
  const pleaseSelectAProductBeforeProceeding = TranslatorFunction({ path: 'pleaseSelectAProductBeforeProceeding.message' });
  const selectAPaymentMethod = TranslatorFunction({ path: 'selectAPaymentMethod.message' });

  const takeAway = TranslatorFunction({ path: 'takeAway.message' });
  const cartQuantity = TranslatorFunction({ path: 'cartQuantity.message' });
  const cartItem = TranslatorFunction({ path: 'cartItem.message' });
  const cartValue = TranslatorFunction({ path: 'cartValue.message' });
  const min = TranslatorFunction({ path: 'minutes.message' });
  const discount = TranslatorFunction({ path: "discount.message" });
  const headerCart = [cartQuantity, cartItem, cartValue, ''];

  const [selectedCityAndNgh, setSelectedCityAndNgh] = useState({
    cityId: '',
    cityName: '',
    neighborhoodName: '',
    shippingPrice: 0,
  });

  const [isMoney, setIsMoney] = useState(false);

  const { domain } = useParams();
  const history = useHistory();
  const handleSuccessMandatoryData = (data) => {
    setDataAPI(data);
    setIsLoading(false);
  };




  const fetchMandatoryData = () =>
    restMenu
      .fetchMandatoryData(domain)
      .then(({ data }) => handleSuccessMandatoryData(data));

  const handleSuccessResponse = (res) => {
    const formattedData = res.map((e) => ({
      ...e,
      active: false,
      type: e.type.map((tp) => ({ name: tp.name ?? '', active: false })),
    }));

    formattedData.forEach((val, indx) => {
      if (val._id === cart.payment.paymentId) {
        val.active = true;

        formattedData[indx].type.forEach((tp) => {
          if (tp.name === cart.payment.type) {
            tp.active = true;
          }
        });
      }
    });

    formattedData.forEach((val) => {
      if (val.name === 'Dinheiro') {
        val.payback = cart?.payment?.payback?.toString() ?? '';
      }
    });
    setPayment(formattedData);
  };

  const fetchPaymentForms = () =>
    restMenu
      .fetchPaymentMethods(domain.toLowerCase())
      .then(({ data }) => handleSuccessResponse(data));

  const sum = (products) => {
    let res = 0;
    products.forEach((prod) => {
      res += prod.priceProductTotal;
    });

    return res;
  };

  const handleClickRemoveItem = (event) => {
    const { name: index } = event.target;
    const newData = { ...cart };

    newData.payment.total -= newData.products[index].priceProductTotal;

    newData.products.splice(index, 1);

    setCart(newData);
    setCartStorage(newData);
  };

  const handleResetCart = () => {
    const newData = { ...cart };

    newData.products = [];

    setCart(newData);
    setCartStorage(newData);
  };

  const handleToggleOnClickType = (event) => {
    const { value } = event.target;
    const newData = { ...cart };

    newData.payment.type = value;

    setCart(newData);
    setCartStorage(newData);
  };

  const handleToggleOnClickPayment = (event) => {
    const { value, name } = event.target;
    const newData = { ...cart };
    // const total =
    //   cart.products.length > 1
    //     ? sum(cart.products)
    //     : cart.products[0]?.priceProductTotal === undefined
    //     ? 0
    //     : cart.products[0].priceProductTotal;

    newData.payment.paymentId = name;
    // newData.payment.total = total;

    const money = value === 'Dinheiro';

    setIsMoney(money);

    if (!money) {
      newData.payment.payback = '';
    }

    setCart(newData);
    setCartStorage(newData);
  };

  const handleOnChangeInput = (event) => {
    const value = event;
    const newData = { ...cart };
    newData.payment.payback = currencyMask(value);
    setCart(newData);
    setCartStorage(newData);
  };

  const handleBlur = (value) => {
    const valueFormatted = removeCurrencyMask(value);
    if (parseFloat(valueFormatted) === 0 || valueFormatted === '') {
      setHasError(false);
      return;
    }

    if (getFinalValue() > valueFormatted) {
      setOpen(true);
      setHasError(true);
      setNameError(
        `${youMustAskForChangeForAnAmountGreaterThan} ${formatPriceWithSeparato(
          getFinalValue()
        )}`
      );
      return;
    }
    setNameError('');
    setHasError(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;

    setOpen(false);
  };

  const handleChangeInputForm = (name, value) => {
    const newDataForm = { ...cart };
    if (name === 'cpf') {
      setCart({ ...cart, [name]: cpfMask(value) });
      setCartStorage({ ...cart, [name]: cpfMask(value) });
      return;
    }
    if (name === 'phone') {
      setCart({
        ...cart,
        [name]: mask(unMask(value), ['(99) 99999-9999']),
      });
      setCartStorage({
        ...cart,
        [name]: mask(unMask(value), ['(99) 99999-9999']),
      });
      return;
    }
    if (name === 'cep') {
      newDataForm.address[name] = cepMask(value);
      setCart(newDataForm);
      setCartStorage(newDataForm);
      return;
    }
    if (name === 'street') {
      newDataForm.address[name] = value;
      setCart(newDataForm);
      return;
    }
    if (name === 'street_number') {
      newDataForm.address[name] = onlyNumbers(value);
      setCart(newDataForm);
      setCartStorage(newDataForm);
      return;
    }
    if (name === 'street_complement') {
      newDataForm.address[name] = value;
      setCart(newDataForm);
      setCartStorage(newDataForm);
      return;
    }
    if (name === 'reference') {
      newDataForm.address[name] = value;
      setCart(newDataForm);
      setCartStorage(newDataForm);
    }
    newDataForm[name] = name === 'coupon' ? value.toUpperCase().trim() : value;
    setCart(newDataForm);
    setCartStorage(newDataForm);
    return;
  };
  const handleSaveCart = async () => {
    setIsLoading(true);
    const userFantasyName =
      getFantasyName() === 'null' ? '' : `*${getFantasyName()}*\n\n`;
    const fantasyName = `${userFantasyName}`;
    const newDataForm = { ...cart };
    newDataForm.clientId = genRandomIdHash();

    if (typeof newDataForm.payment.payback === 'string') {
      if (isMoney || newDataForm.payment.payback.replace(' ', '') === 'R$') {
        const floatValuePayback = parseFloat(
          removeCurrencyMask(newDataForm.payment.payback)
        );

        const payback =
          typeof floatValuePayback === 'string' ? 0 : floatValuePayback;

        newDataForm.payment.payback = payback;
      }
    }

    if (!newDataForm.isValidCoupon.isValid && newDataForm.coupon !== '') {
      newDataForm.coupon = undefined;
    }


    newDataForm.payment.total = getFinalValue();

    setCart(newDataForm);
    setCartStorage(newDataForm);

    const textProducts = `${cart.products.map((product) => {
      const existComplementGroup =
        product.complementGroup.filter(
          (complementGroup) => complementGroup.quantity > 0
        ).length > 0;
      const textComplementGroup = existComplementGroup
        ? `- Complementos:\n${product.complementGroup
          .filter((complementGroup) => complementGroup.quantity > 0)
          .map(
            (complementGroup) =>
              ` *- ${complementGroup.name}*\n${complementGroup.complements
                .filter((complement) => complement.quantity > 0)
                .map(
                  (complement) =>
                    `  *- ${complement.quantity}x ${complement.name}${complement.price > 0
                      ? ` (R$ ${formatPriceWithSeparato(
                        complement.price
                      )})*`
                      : '*'
                    }\n`
                )
                .join('')}\n`
          )
          .join('')}\n`
        : '';
      const observation =
        product.observation.length > 0
          ? `Observações:\n${product.observation}\n`
          : '';
      return `Categoria: ${product.category}\n*${product.name}*\nQuantidade: *${product.quantity
        }*\nPreço unitário: R$ ${formatPriceWithSeparato(
          product.priceProduct
        )}\nPreço Total: R$ ${formatPriceWithSeparato(
          product.priceProductTotal
        )}\n${observation}${textComplementGroup}\n`;
    })}`;

    let textClient = '';

    if (dataAPI.mandatory.name) {
      textClient += `*Nome:* ${cart.clientName ?? 'Não Informado'}\n`;
    }

    if (dataAPI.mandatory.cpf) {
      textClient += `*CPF*: ${cart.cpf ?? 'Não Informado'}\n`;
    }

    if (dataAPI.mandatory.cep) {
      textClient += `*CEP*: ${cart.address.cep ?? 'Não informado'}\n`;
    }

    if (dataAPI.mandatory.phone) {
      textClient += `*Telefone*: ${cart.phone ?? 'Não informado'}\n`;
    }

    const filteredPaymentName = payment.filter(
      (pay) => pay._id === cart.payment.paymentId
    )[0];

    let textAddress = '';

    if (dataAPI.mandatory.address) {
      textAddress = `*Rua*: ${cart.address.street}\n*Numero*: ${cart.address.street_number}\n*Complemento*: ${cart.address.street_complement}\n*Cidade*: ${cart.address.city} - *Bairro*: ${cart.address.neighborhood}`;
    }

    let textPayment = '';
    if (
      filteredPaymentName.name === 'Dinheiro' &&
      typeof cart.payment.payback === 'string'
    ) {
      if (
        cart.payment.payback !== 0 &&
        cart.payment.payback !== null &&
        !isNaN(cart.payment.payback) &&
        cart.payment.payback.replace(' ', '') !== 'R$' &&
        cart.payment.payback.replace(' ', '') !== ''
      ) {
        textPayment = `*Método de Pagamento:* ${filteredPaymentName.name
          }\n*Troco para*: R$ ${formatPriceWithSeparato(cart.payment.payback)}`;
      } else
        textPayment = `*Método de Pagamento:* ${filteredPaymentName.name}\n`;
    }

    if (
      filteredPaymentName.name === 'Dinheiro' &&
      typeof cart.payment.payback === 'number'
    ) {
      if (cart.payment.payback !== 0 && cart.payment.payback !== null) {
        textPayment = `*Método de Pagamento:* ${filteredPaymentName.name
          }\n*Troco para*: R$ ${formatPriceWithSeparato(cart.payment.payback)}`;
      } else
        textPayment = `*Método de Pagamento:* ${filteredPaymentName.name}\n`;
    }

    if (filteredPaymentName.name === 'Pix') {
      textPayment = `*Método de Pagamento:* ${filteredPaymentName.name}\n*Chave Pix*: ${cart.payment.type}`;
    }

    if (
      filteredPaymentName.name !== 'Pix' &&
      filteredPaymentName.name !== 'Dinheiro' &&
      filteredPaymentName.name !== 'Cheque'
    ) {
      textPayment = `*Forma de Pagamento:* ${filteredPaymentName.name} (${cart.payment.type || 'Não informada'
        })`;
    }

    let textFormatted = `${fantasyName}*${dayjs(new Date()).format(
      'DD/MM/YYYY HH:mm'
    )}*\n\nOlá, gostaria de fazer um pedido\n\nProdutos:\n${textProducts}*Subtotal.:* R$ ${getFinalValue().toFixed(2).replace(".", ",")}\n*Entrega..:* ${cart?.address.city !== takeAway
      ? `R$ ${formatPriceWithSeparato(cart.payment.shippingPrice)}`
      : 'Retirar no local'
      }\n*Total.......:* R$ ${getFinalValue().toFixed(2).replace(".", ",")}\n\n${textPayment}\n\n
    `;

    if (
      dataAPI.mandatory.address &&
      cart?.address.city !== takeAway
    ) {
      textFormatted += `*ENTREGA:*\n*Endereço para entrega:*\n${textAddress}\n\n`;
    }

    textFormatted += `${textClient}\n`;

    const encodeText = encodeURI(textFormatted);
    const isDelivery = cart?.address.city !== takeAway;
    const serviceMode = isDelivery ? getOperationId() : getTakeaway();

    const facebookData = cart.products.map((prod) => ({
      id: prod.productId,
      quantity: prod.quantity,
      price: prod.priceProductTotal,
    }));

    try {
      const result = await restMenu.postOrder(domain, {
        ...newDataForm,
        serviceMode,
      });
      if (result && result.data) {
        ReactPixel.track('Purchase', {
          currency: 'BRL',
          value: Number(getFinalValue()),
          content_type: 'product',
          contents: facebookData,
          delivery_category: isDelivery ? 'home_delivery' : 'curbside',
        });
        setOfficePhone(
          `55${removeMaskPhone(dataAPI.deliverySettings.officePhone ?? '')}`
        );
        setEncodedText(encodeText);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const filterNeighborhoodByCity = (neighborhood) => {
    if (!neighborhood) return false;
    return neighborhood.cityId === selectedCityAndNgh.cityId;
  };

  const filteredNeighborhood = useMemo(
    () => dataAPI.neighborhood.filter(filterNeighborhoodByCity),
    [selectedCityAndNgh]
  );

  const handleChangeCity = (event) => {
    const { name, value } = event.target;
    const isOldSelected = name === selectedCityAndNgh;
    const newDataForm = { ...cart };
    newDataForm.address.city = value;

    setCart(newDataForm);
    setCartStorage(newDataForm);

    if (!isOldSelected) {
      setCart({
        ...cart,
        payment: { ...cart.payment, shippingPrice: 0 },
      });
      setCartStorage({
        ...cart,
        payment: { ...cart.payment, shippingPrice: 0 },
      });
    }

    setSelectedCityAndNgh({
      cityId: name,
      cityName: value,
      neighborhoodName: '',
    });
  };

  const handleSelectNeighborhood = (event) => {
    let { name, value } = event.target;

    // Verifica se existe frete grátis
    // Verifica se o frete grátis é maior que 0
    if (dataAPI.deliverySettings.freeShipping && dataAPI.deliverySettings.freeShipping > 0) {
      // Armazena o valor dos lanches sem o frete
      let foodValue = 0;

      // Soma o valor dos produtos
      cart.products.map(product => {
        foodValue += product.priceProductTotal;
      });

      // Valida se o preço final é maior que o mínimo para a entrega
      if (foodValue > dataAPI.deliverySettings.freeShipping) {
        // Zera o preço do frete
        name = 0;
      }
    }

    setCart({
      ...cart,
      address: { ...cart.address, neighborhood: value },
      payment: { ...cart.payment, shippingPrice: parseFloat(name) },
    });
    setCartStorage({
      ...cart,
      address: { ...cart.address, neighborhood: value },
      payment: { ...cart.payment, shippingPrice: parseFloat(name) },
    });

    if (
      cart.isValidCoupon.isValid &&
      cart.isValidCoupon.dataCoupon.type === 'shipping'
    ) {
      setCart({
        ...cart,
        payment: { ...cart.payment, shippingPrice: 0 },
      });
      setCartStorage({
        ...cart,
        payment: { ...cart.payment, shippingPrice: 0 },
      });
    }

    setSelectedCityAndNgh({
      ...selectedCityAndNgh,
      neighborhoodName: value,
      shippingPrice: parseFloat(name),
    });
  };

  const testCPF = (strCPF) => {

    // Remove a mascara do cpf
    let cpfWithoutMask = strCPF.replace(".", "").replace(".", "").replace("-", "");

    // Regex para verificar se todos os números são iguais
    const regex = /^([0-9])\1+$/;
    // Testando o regex com o CPF
    if (regex.test(cpfWithoutMask)) return false;

    var Soma;
    var Resto;
    Soma = 0;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(cpfWithoutMask.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(cpfWithoutMask.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(cpfWithoutMask.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(cpfWithoutMask.substring(10, 11))) return false;
    return true;
  }



  const isValidForm = () => {
    let valid = true;
    let error = '';

    if ( (cart.coupon !== '' && cart.isValidCoupon.isValid === false)) {
      error = 'Cupom Inserido invalido, insirá um novo ou remova';
      valid = valid && false;
    }

    if (cart.address.city !== takeAway) {
      if (
        selectedCityAndNgh.cityId === '' ||
        selectedCityAndNgh.neighborhoodName === ''
      )
        valid = false;
    }

    if (dataAPI.mandatory.cep) valid = valid && cart.address.cep !== '';

    if (dataAPI.mandatory.cpf && !testCPF(cart.cpf)) { //
      valid = false;
      error = 'CPF inválido';
    }

    if (dataAPI.mandatory.name) valid = valid && cart.clientName !== '';

    if (dataAPI.mandatory.address && cart.address.city !== takeAway) {
      const newVal = { ...cart.address };
      delete newVal.state;
      delete newVal.cep;
      delete newVal.street_complement;
      delete newVal.reference;
      const values = Object.values(newVal);
      const isValid = values.every((e) => e !== '');
      valid = valid && isValid;
    }

    if (dataAPI.mandatory.phone) valid = valid && cart.phone !== '';

    if (valid === false && (error === '' || !error)) error = checkAllFields;

    if (cart.products.length === 0) {
      error = pleaseSelectAProductBeforeProceeding;
      valid = valid && false;
    }
   
    if (cart.payment.paymentId === '') {
      error = selectAPaymentMethod;
      valid = valid && false;
    }

    if (
      isMoney &&
      cart.payment.payback !== 0 &&
      cart.payment.payback !== null &&
      cart.payment.payback.replace(' ', '') !== 'R$' &&
      cart.payment.payback.replace(' ', '') !== ''
    ) {
      const paybackError =
        hasError ||
        getFinalValue() >
        removeCurrencyMask(cart.payment.payback ?? 'R$ 0,00');
      if (paybackError) {
        error = `${youMustAskForChangeForAnAmountGreaterThan} ${formatPriceWithSeparato(
          getFinalValue()
        )}`;
        valid = valid && false;
      }
    }

    // Verifica se é entrega
    // Verifica se existe um valor mínimo para compra
    // Verifica se existe o valor mínimo é superior a 0
    if (cart.address.city !== takeAway && dataAPI.deliverySettings.minValue && dataAPI.deliverySettings.minValue > 0) {
      // Armazena o valor dos lanches sem o frete
      let foodValue = 0;

      // Soma o valor dos produtos
      cart.products.map(product => {
        foodValue += product.priceProductTotal;
      });

      // Valida se o preço final é maior que o mínimo para a entrega
      if (foodValue < dataAPI.deliverySettings.minValue) {
        valid = false;
        error = 'Valor mínimo para pedidos com entrega é de: R$' + dataAPI.deliverySettings.minValue;
      }
    }

    setOpen(!valid);
    setNameError(error);
    return valid;
  };

  const onClickSaveButton = () => {
    const isValid = isValidForm();
    if (isValid && !hasError) handleSaveCart();
  };

  const onClickSendButton = () => {
    setEncodedText('');
    setOfficePhone('');

    handleResetCart();

    history.push(`/${domain}`);
  };

  const proccessCoupon = (coupon) => {
    if (coupon.isValid) {
      const oldValues = { ...cart };

      if (coupon.dataCoupon.type === 'shipping') {
        oldValues.payment.shippingPrice = 0;
      }

      if (coupon.dataCoupon.type === 'discountValue') {
        const discountedValue =
          oldValues.payment.total - coupon.dataCoupon.discountValue;
        oldValues.payment.total = discountedValue < 0 ? 0 : discountedValue;
      }
      if (coupon.dataCoupon.type === 'discountPercent') {
        const discountedValue =
          oldValues.payment.total -
          oldValues.payment.total * (coupon.dataCoupon.discountPercent / 100);
        oldValues.payment.total = discountedValue < 0 ? 0 : discountedValue;
      }

      setCart({ ...oldValues });
      setCartStorage({ ...oldValues });
    }
  };

  const validateCoupon = async (value) => {
    try {
      const { data } = await restMenu.verifyCoupon(domain, value);
      const { coupon } = data;

      const oldCart = { ...cart };

      const sumSubtotal = sum(cart.products);

      oldCart.payment.total = sumSubtotal;
      oldCart.payment.shippingPrice = selectedCityAndNgh.shippingPrice;

      let text = coupon === 'Invalid' ? invalidCoupon : discountApplied;
      if (!coupon) {
        oldCart.isValidCoupon.isValid = false;
        oldCart.isValidCoupon.couponCode = '';
        oldCart.isValidCoupon.dataCoupon = {};
      } 
      else if ((cart.address.city === '' || cart.address.city === "Retirada no Local") && coupon.type == 'shipping') {
        text = 'Cupom de frete grátis não pode ser aplicado em compras sem endereço ou com retirada no local selecionado';
        oldCart.isValidCoupon.isValid = false;
        oldCart.isValidCoupon.couponCode = '';
        oldCart.isValidCoupon.dataCoupon = {};
        setOpen(true);
        setNameError(text);
      } else {
        setOpen(true);
        setNameError(text);
        oldCart.isValidCoupon.isValid = coupon === 'Invalid' ? false : true;
        oldCart.isValidCoupon.couponCode = value;
        oldCart.isValidCoupon.dataCoupon = coupon;
      }

      setCart({ ...oldCart });
      setCartStorage({ ...oldCart });

      proccessCoupon(oldCart.isValidCoupon);
    } catch (err) {
      console.log(err);
    }
  };

  const goToMenu = () => {
    const link = pathname.split('/')[1];

    history.push(`/${link}/delivery/menu`)
  }

  const textConfirm = useMemo(() => {
    let text = '';

    if (cart.address.street.length > 0) {
      text += `${cart.address.street}`;
    }

    if (cart.address.street_number.length > 0) {
      text += `, ${cart.address.street_number} `;
    }

    if (cart.address.neighborhood.length > 0) {
      text += ` - ${cart.address.neighborhood} `;
    }

    if (cart.address.city.length > 0) {
      text += ` - ${cart.address.city} `;
    }

    return text;
  }, [cart]);

  /**
   * Soma o valor total dos produtos
   * Verifica e soma o valor de entrega
   * Verifica e diminui o valor do cupom de desconto
   * @returns O valor final da compra
  */
  const getFinalValue = (onlyProducts = false) => {

    // Variável que guardará o valor final
    let total = 0;

    // Verifica se existe mais de um produto
    if (cart.products.length > 1) {
      // Soma todos os produtos do carrinho
      total = sum(cart.products);
    } else {
      // Verifica se existe um produto no carrinho
      if (cart.products[0] !== undefined) {
        // Adiciona o valor do produto no total
        total = cart.products[0].priceProductTotal;
      }
    }

    // Retorna apenas o valor dos produtos sem desconto ou frete
    if (onlyProducts) {
      return total;
    }

    // Verifica se existe cupom de desconto
    if (cart.isValidCoupon.isValid) {
      // Verifica se o cupom de desconto é para preço
      if (cart.isValidCoupon.dataCoupon.type !== 'shipping') {
        // Verifica se o tipo de desconto é um valor fixo
        if (cart.isValidCoupon.dataCoupon.type === "discountValue") {
          // Subtrai o valor do total
          total -= cart.isValidCoupon.dataCoupon.discountValue;
        } else {
          // Captura o valor de desconto
          let discountValue = total * (cart.isValidCoupon.dataCoupon.discountPercent / 100);
          // Diminui o total conforme a percentagem
          total = total - discountValue;
        }
      }
    }

    // Verifica se há preço de entrega
    if (cart.payment.shippingPrice) {
      // Soma o preço de entrega ao valor final
      total += cart.payment.shippingPrice;
    }

    // Verifica se o total está abaixo de 0
    // Se estiver coloca o valor como 0
    // Se não mantém o valor da variável total
    total = total < 0 ? 0 : total;

    // Formata o preço e retorna para o front
    return total;
  }

  useEffect(() => {
    scrollToTop();
    fetchPaymentForms();
    fetchMandatoryData();
    const subtotal = sum(cart.products);
    if (payment.length > 0) {
      setCart({
        ...loadCartStorage,
        payment: { ...loadCartStorage.payment, total: subtotal },
      });
      setCartStorage({
        ...loadCartStorage,
        payment: { ...loadCartStorage.payment, total: subtotal },
      });
    }
    proccessCoupon(cart.isValidCoupon);
  }, []);

  return (
    <BlockLoader blocking={isLoading}>
      <Container>
        <TitleWrapper>
          <h1>
            {myOrder}
          </h1>
        </TitleWrapper>
        {cart.products && cart.products.length > 0 && (
          <TableCart
            head={headerCart}
            bodyData={cart.products}
            onClickRemove={handleClickRemoveItem}
          />
        )}
        <ContainerButtons>
          <h2 onClick={handleResetCart}>
            {emptyCart}
          </h2>
          <Link to={`/${domain}/delivery/menu`}>
            <h1>
              {addMoreItems}
            </h1>
          </Link>
        </ContainerButtons>
        <ContainerSubtotal>
          <h1>
            {subtotalTranslate}
          </h1>
          <h1>
            R$
            {formatPriceWithSeparato(
              cart.products.length > 1
                ? sum(cart.products)
                : cart.products[0]?.priceProductTotal === undefined
                  ? 0
                  : cart.products[0].priceProductTotal
            )}
          </h1>

        </ContainerSubtotal>
        <Identification
          dataAPI={dataAPI}
          cities={dataAPI.cities}
          dataForm={cart}
          handleChangeInput={handleChangeInputForm}
          handleChangeCity={handleChangeCity}
          filteredNeighborhood={orderAlphabetical(filteredNeighborhood, 'name')}
          handleSelectNeighborhood={handleSelectNeighborhood}
          selectedValues={selectedCityAndNgh}
          validateCoupon={validateCoupon}
          couponValid={cart.isValidCoupon.isValid}
        />

        <ContainerPayment>
          <h1>
            {formOfPayment}
          </h1>
          <AccordionComponent
            data={payment}
            ToggleOnClickPayment={handleToggleOnClickPayment}
            ToggleOnClickType={handleToggleOnClickType}
            ToggleOnClickInput={handleOnChangeInput}
            ToggleOnBlurInput={handleBlur}
            selectedPayment={cart.payment}
          />
        </ContainerPayment>

        <ContainerResults>
          <div className="total">
            {cart?.address.city !== takeAway && (
              <div>
                <h1>
                  {subtotalTranslate}
                </h1>
                <h1>{`R$ ${formatPriceWithSeparato(
                  cart.products.length > 1
                    ? sum(cart.products)
                    : cart.products[0]?.priceProductTotal === undefined
                      ? 0
                      : cart.products[0].priceProductTotal
                )}`}</h1>
              </div>
            )}

            {cart.isValidCoupon.isValid && cart.isValidCoupon.dataCoupon.type !== 'shipping' ?
              <div>
                <h1> {discount}: </h1>
                <h1>
                  RS: ${cart.isValidCoupon.dataCoupon.type === "discountValue" ?
                    cart.isValidCoupon.dataCoupon.discountValue.toFixed(2).replace(".", ",")
                    :
                    (getFinalValue(true) * (cart.isValidCoupon.dataCoupon.discountPercent / 100)).toFixed(2).replace(".", ",")}
                </h1>
              </div>
              : ''}

            <div>
              {cart?.address.city !== takeAway && (
                <>
                  <h1>
                    {deliveryFee}
                    <br />
                    <h4>
                      {dataAPI?.deliverySettings?.minDeliveryTime &&
                        dataAPI?.deliverySettings?.maxDeliveryTime &&
                        `${dataAPI.deliverySettings.minDeliveryTime} 
              - ${dataAPI.deliverySettings.maxDeliveryTime}  ${min}`}
                    </h4>
                  </h1>
                  <h1>
                    {`R$ ${formatPriceWithSeparato(
                      cart.payment.shippingPrice ?? 0
                    )}`}
                  </h1>
                </>
              )}
            </div>
            <div className="totalnumber">
              <h1>
                {totalTranslate}
              </h1>
              <h1>
                {`R$ ${getFinalValue().toFixed(2).replace(".", ",")}`}
              </h1>
            
            </div>
          </div>
        </ContainerResults>

        {cart?.address.city !== takeAway && (
          <DeliveryIn>
            <h3>
              {deliveryInTranslate}
              :</h3>
            <h4>{textConfirm}</h4>
          </DeliveryIn>
        )}

        <ContainerButtonsFooter>
          {encodedText !== '' && (
            <LinkStyled
              type="primary"
              target="_blank"
              href={`https://api.whatsapp.com/send?phone=${officePhone}&text=${encodedText}`}
              onClick={onClickSendButton}
            >
              {sendOrder}
            </LinkStyled>
          )}

          {encodedText === '' && (
            <Button
              type="secondary"
              onClick={onClickSaveButton}
              disabled={isLoading}
            >
              {cart.address.city !== takeAway
                ? confirmAddress
                : confirmTakAway}
            </Button>
          )}

          <ResponseTime>
            {ourResponseTimeIsUpTo + " "}
            {dataAPI?.deliverySettings?.responseTime}
            {" " + minutes}
          </ResponseTime>
          <LinkComponent onClick={goToMenu}>
            {backToMenu}
          </LinkComponent>
        </ContainerButtonsFooter>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity={
              nameError === 'Desconto aplicado.' ? 'success' : 'warning'
            }
            color={nameError === 'Desconto aplicado.' ? 'success' : 'warning'}
          >
            {nameError}
            
          </MuiAlert>
        </Snackbar>
      </Container>

    </BlockLoader>
    
  );
}


export default Cart;
