import React from "react";

import { Container } from "./styles";

import ClosedComponent from "../../components/Closed";

function Closed(props) {
  const { hours } = props.location.state[0];
  return (
    <Container>
      <ClosedComponent hours={hours} />
    </Container>
  );
}

export default Closed;
