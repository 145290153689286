import styled from "styled-components";

const PageWrapper = styled.div`
  background: ${({ theme }) => theme.colors.mainColorContrast};
  overflow: hidden;
`;

const TitleWrapper = styled.div`
  margin-top: 130px;
  width: 70%;
  margin: 130px auto 60px;
  h1 {
    font-weight: 300;
    line-height: 120%;
    font-size: 32px;
    text-align: center;
  }
`;
const ButtonWrapper = styled.div`
  width: 90%;
  margin: 0 auto;

  h2 {
    font-weight: 300;
    line-height: 120%;
    font-size: 24px;
    text-align: center;
  }

  button {
    margin-top: 24px;
  }

  h3 {
    color: white;
  }
`;

export { PageWrapper, TitleWrapper, ButtonWrapper };
