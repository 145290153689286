import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import ArrowIcon from "../../assets/img/ic-forward.svg";
import { Arrow, FoodImg, List, ListItem } from "./SubCategoryListStyles";
import TranslatorFunction from "../I18n/Translator";

const SubCategoryList = ({ subCategories, categoryName, products }) => {

  const language = TranslatorFunction({ path: 'language.message' });
  return (
    <List>
      {subCategories.map((subCategory, index) =>
      {
      return (
        <Link
          to={{
            pathname: `${categoryName}/${encodeURIComponent(subCategory.translatedName[0] === undefined ||  subCategory.translatedName[0].name[0][language] === undefined? subCategory.name : subCategory.translatedName[0].name[0][language])}`,
            state: { subCategory },
          }}
          key={subCategory._id}
        >
          <ListItem index={index}>
            <p>{subCategory.translatedName[0] === undefined ? subCategory.name : (subCategory.translatedName[0].name[0][language] !== undefined ?subCategory.translatedName[0].name[0][language] : subCategory.name)}</p>
            <FoodImg img={subCategory.imageURL} />
            <Arrow secondary={subCategory.imageURL} src={ArrowIcon} />
          </ListItem>
        </Link>
      )}
      )}
    </List>
  );
};

SubCategoryList.defaultProps = {
  subCategories: [],
  categoryName: "",
};

SubCategoryList.propTypes = {
  subCategories: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  categoryName: PropTypes.string,
};

export default SubCategoryList;
