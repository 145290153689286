import { useLocation } from "react-router-dom";

const useOperation = () => {
  const location = useLocation();
  const operation = location.pathname.split("/").filter((str) => str !== "")[1];

  return operation;
};

export default useOperation;
