import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import SubCategoryList from '../../components/subCategoryList/SubCategoryList';
import PageTitle from './SubCategoriesStyles';
import restMenus from '../../api/menus/rest-menus';
import { scrollToTop } from '../../utils';
import TranslatorFunction from '../../components/I18n/Translator';

const SubCategory = () => {
  const language = TranslatorFunction({ path: 'language.message' });

  const { category: categoryName, domain } = useParams();
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const location = useLocation();
  const { category } = location?.state;

  const handleMenusResponse = (response) => {
    const subCategories = response.data.subCategories;
    setSubCategoriesList(subCategories);
  };

  const fecthMenusCategory = () =>
    restMenus
      .fecthMenusCategory(domain, category?._id)
      .then(handleMenusResponse);

  useEffect(() => {
    fecthMenusCategory();
    scrollToTop();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <PageTitle>{ category?.translatedName[0]?category.translatedName[0].name[0][language]: categoryName}</PageTitle>
      <SubCategoryList
        subCategories={subCategoriesList}
        categoryName={categoryName}
      />
    </>
  );
};

export default SubCategory;
