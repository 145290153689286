import styled from 'styled-components';

const Footer = styled.div`
  height: fit-content;
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.mainColorContrast};

  svg {
    margin-bottom: 14px;
    path {
      fill: ${({ theme }) => theme.colors.myTripMenuLogoColor};
    }
  }

  p {
    margin-bottom: 0px;
    font-size: 9px;
    line-height: 120%;
    color: ${({ theme }) => theme.colors.myTripMenuLogoColor};
  }

  b {
    color: ${({ theme }) => theme.colors.mainColor};
  }
`;

const ChildrenWrapper = styled.div`
  min-height: calc(100vh - 60px - 147px);
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  width: 100%;
  overflow-x: hidden;
  background: ${({ theme }) => theme.colors.mainColorContrast};
`;

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
  background: ${({ theme }) => theme.colors.mainColorContrast};
`;

export {
  Footer,
  ChildrenWrapper,
  LayoutWrapper,
};
