import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Source Sans Pro', sans-serif;
    color: ${({ theme }) => theme.colors.textColor};

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  a {
    text-decoration: none;
  }
`;
