import styled from "styled-components";

const PageWrapper = styled.div`
  background: ${({ theme }) => theme.colors.mainColorContrast};
  overflow: hidden;
`;

const TitleWrapper = styled.div`
  margin-top: 28px;
  margin-left: 24px;
  h1 {
    font-weight: 300;
    line-height: 120%;
    font-size: 28px;
    font-weight: lighter;
    color: #858585;
  }
  h2 {
    color: #858585;
    font-weight: lighter;
    font-size: 24px;
  }
`;
const ButtonWrapper = styled.div`
  width: 90%;
  margin: 0 auto;

  h2 {
    font-weight: 300;
    line-height: 120%;
    font-size: 24px;
    text-align: center;
  }

  button {
    margin-top: 24px;
  }

  h3 {
    color: white;
  }
`;

export const CarouselWrapper = styled.div``;

export { PageWrapper, TitleWrapper, ButtonWrapper };
