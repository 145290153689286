import React from "react";
import { ThemeProvider } from "styled-components";

import WebDisclaimer from "./components/webDisclaimer/WebDisclaimer";
import defaultTheme from "./styles/themes/defaultTheme";
import GlobalStyle from "./styles/global";

import { CartProvider } from "./hooks/CartContext";

import BloackLoadingProvider from "./contexts/BlockLoaderContext";
import Router from "./routes";

const App = () => (
  <CartProvider>
    <ThemeProvider theme={defaultTheme}>
      <BloackLoadingProvider>
        <GlobalStyle />
        <WebDisclaimer>
          
          <Router />
        </WebDisclaimer>
      </BloackLoadingProvider>
    </ThemeProvider>
  </CartProvider>
);

export default App;
