export const MenuForm = {
  clientId: "",
  clientName: "",
  cpf: "",
  phone: "",
  address: {
    cep: "",
    city: "",
    neighborhood: "",
    street: "",
    street_number: "",
    street_complement: "",
    state: "",
    reference: "",
  },
  payment: {
    paymentId: "",
    payback: 0,
    type: "",
    total: 0,
    shippingPrice: 0,
  },
  coupon: "",
  isValidCoupon: {
    couponCode: "",
    dataCoupon: {},
    isValid: false,
  },
  products: [],
};
