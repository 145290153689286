import styled, { keyframes } from "styled-components";
import { ReactSVG } from "react-svg";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const HeaderContainer = styled.header`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 60px;
  padding: 0 16px;
  z-index: 9999;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: ${({ isHome }) => (isHome ? "center" : "space-between")};
  overflow: none;
  transition: 0.2s;
  background: ${({ theme }) => theme.colors.mainColorContrast};
`;

const MainColorIcon = styled(ReactSVG)`
  animation-name: ${fadeIn};
  animation-duration: ${({ invisible }) => (invisible ? 0 : "0.3s")};
  opacity: ${({ invisible }) => (invisible ? 0 : 1)};
  svg path {
    fill: ${({ theme }) => theme.colors.mainColor};
  }
`;

const FantasyName = styled.h1`
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;
  text-align: center;
  /* margin-left: ${({ isHome }) => !isHome && "40%"}; */
  fill: ${({ theme }) => theme.colors.mainColorDark};
`;

const ContainerIcons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContentIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ isDelivery }) => (isDelivery ? "50%" : "")};
`;

export {
  HeaderContainer,
  MainColorIcon,
  FantasyName,
  ContainerIcons,
  ContentIcons,
};
