import ptBrTranslations from './pt-br';
import enUsTranslations from './en-us';
import esEsTranslations from './es-es';

import ptTranslations from './pt';
import deTranslations from './de';
import itTranslations from './it';
import jaTranslations from './ja';
import zhTWTranslations from './zh-TW';
import koTranslations from './ko';
import ruTranslations from './ru';
import arTranslations from './ar';
import frTranslations from './fr';




export default {
  'pt-BR': ptBrTranslations,
  'en-US': enUsTranslations,
  'es-ES': esEsTranslations,

  'pt': ptTranslations,
  'de': deTranslations,
  'it': itTranslations,
  'ja': jaTranslations,
  'zh-TW': zhTWTranslations,
  'ko': koTranslations,
  'ru': ruTranslations,
  'ar': arTranslations,
  'fr': frTranslations,
  
};