import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import PageWrapper from "../layouts/pageWrapper/PageWrapper";

import HomePage from "../pages/home/Home";
import ProductsPage from "../pages/products/Products";
import ProductInfoPage from "../pages/productInfo/ProductInfo";
import SearchPage from "../pages/search/Search";
import SubCategory from "../pages/subcategories/SubCategories";
import HomeLocal from "../pages/local/HomeLocal";
import Closed from "../pages/Closed";
import Menu from "../pages/Menu";
import Cart from "../pages/cart";
import Identification from "../pages/Identification";
import ProductInfoDeliveryPage from "../pages/delivery/productsInfoDelivery";

function Router() {
  return (
    <BrowserRouter>
      <PageWrapper>
        <Switch>
          <Route path="/:domain/:servicemode/closed" exact component={Closed} />
          <Route path="/:domain/:servicemode/menu" exact component={Menu} />
          <Route
            path="/:domain/delivery/:category/:subcategory/:product"
            exact
            component={ProductInfoDeliveryPage}
          />
          <Route path="/:domain/:servicemode/search" component={SearchPage} />
          <Route
            path="/:domain/:servicemode/:category/:subcategory/:product"
            exact
            component={ProductInfoPage}
          />

          <Route
            path="/:domain/:servicemode/:category/:subcategory"
            exact
            component={ProductsPage}
          />
          <Route
            path="/:domain/order/identification"
            exact
            component={Identification}
          />
          <Route path="/:domain/order" exact component={Cart} />
          <Route path="/:domain/local" exact component={HomeLocal} />
          <Route
            path="/:domain/:servicemode/:category"
            component={SubCategory}
          />
          <Route path="/:domain" exact component={HomePage} />
          <Redirect to="/haka" />
        </Switch>
      </PageWrapper>
    </BrowserRouter>
  );
}

export default Router;
