export const cpfMask = (value) => {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const phoneMask = (v) => {
  let phone = v.replace(/D/g, ""); //Remove tudo o que não é dígito
  phone = phone.replace(/^(d{2})(d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
  phone = phone.replace(/(d)(d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
  return phone;
};

export const cepMask = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/^(\d{5})(\d)/, "$1-$2")
    .replace(/^(.{9}).*$/, "$1");
};

export const currencyMask = (value) => {
  let v = value;
  v = v.replace(/\D/g, ""); // permite digitar apenas numero
  v = v.replace(/(\d{1})(\d{15})$/, "$1.$2"); // coloca ponto antes dos ultimos digitos
  v = v.replace(/(\d{1})(\d{11})$/, "$1.$2"); // coloca ponto antes dos ultimos 13 digitos
  v = v.replace(/(\d{1})(\d{8})$/, "$1.$2"); // coloca ponto antes dos ultimos 10 digitos
  v = v.replace(/(\d{1})(\d{5})$/, "$1.$2"); // coloca ponto antes dos ultimos 7 digitos
  v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2"); // coloca virgula antes dos ultimos 4 digitos
  return `R$ ${v}`;
};

export const removeCurrencyMask = (value) => {
  return value.replace(/[^0-9,]*/g, "").replace(",", ".");
};

export const onlyNumbers = (value) => {
  return value.replace(/([^\d])+/gim, "");
};

export const removeMaskPhone = (value) => value.replace(/[^0-9]+/g, "");
