import styled from 'styled-components';

export const Container = styled.div``;

export const ContainerImg = styled.div`
  width: 100%;
  height: ${({ isNullImage }) => (!isNullImage ? 244 : 80)}px;
  background-color: ${({ theme }) => theme.colors.mainColor};

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  img {
    width: 100%;
    height: 100%;
    object-fit: ${({ isNullImage }) => !isNullImage && 'cover'};
  }
`;
