import styled, { keyframes, css } from 'styled-components';
import { ReactSVG } from 'react-svg';

const slideAnimation = keyframes`
  0% { transform: translate(100vh, 0%) }
  100% { transform: translate(0%, 0%) }
`;

const List = styled.section`
  padding-left: 16px;
`;

const ListItem = styled.div`
  position: relative;
  padding-left: 16px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px 0px 0px 16px;
  margin-bottom: 16px;
  background: ${({ theme }) => theme.colors.mainColorContrast};
  overflow: hidden;

  animation-name: ${slideAnimation};
  transform: translate(100vh, 0%);
  animation-fill-mode: forwards;
  animation-duration: ${({ index }) => 0.5 + index / 20}s;
`;

const ContentContainer = styled.div`
    margin-top: 16px;
    margin-bottom: 16px;
    max-width: 55%;

    h2 {
      margin-bottom: 4px;
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      text-transform: uppercase;
      word-wrap: break-word;
      white-space: pre-wrap;
    }
    p {
      margin-bottom: 16px;
      font-weight: 300;
      font-size: 12px;
      line-height: 120%;
      word-wrap: break-word;
      white-space: pre-wrap;

    }
`;

const priceAbsolute = css`
  position: absolute;
  right: 48px;
  top: 50%;
  transform: translate(0%, -50%);
`;

const PriceText = styled.h3`
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.mainColorDark};
  ${({ hasImg }) => (!hasImg ? priceAbsolute : '')}

  @media(max-width: 340px) {
    font-size: 16px;
  }
`;

const skewDeg = -16;

const FoodImg = styled.div`
  height: 70px;
  width: 132px;
  position: absolute;
  top: 0px;
  right: -24px;
  height: 100%;
  width: 45%;
  border-top-left-radius: 16px;
  transform: skew(${skewDeg}deg);
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    background-image: url(${({ img }) => img});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 0px;
    left: -24px;
    width: 100%;
    height: 100%;
    transform: skew(${-skewDeg}deg);
  }
`;

const Arrow = styled(ReactSVG)`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translate(0%, -50%);

  svg path {
    fill: ${({ theme, secondary }) => (secondary ? theme.colors.mainColorContrast : theme.colors.mainColor)};
  }
`;

export {
  List,
  ListItem,
  Arrow,
  FoodImg,
  ContentContainer,
  PriceText,
};
