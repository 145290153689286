import React from "react";
import PropTypes from "prop-types";
import LinkStyled from "./LinkStyles";

const Link = (props) => {
  const { children, to, onClick } = props;
  return (
    <LinkStyled to={to} onClick={onClick}>
      {children}
    </LinkStyled>
  );
};

Link.defaultProps = {
  children: "",
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string,
};

export default Link;
