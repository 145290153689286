import React, { useEffect, useState } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import { ReactSVG } from "react-svg";

import { withStyles } from "@material-ui/core/styles";

import { Container } from "./styles";

import ExpandIcon from "../../assets/img/expandIcon.svg";

import Input from "./InputMoney";

import TranslatorFunction from "../../components/I18n/Translator"

const GreenRadio = withStyles({
  root: {
    color: "#FF5533 !important",
    "&$checked": {
      color: "#FF5533 !important",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function AccordionComponent({
  data,
  ToggleOnClickPayment,
  ToggleOnClickType,
  ToggleOnClickInput,
  ToggleOnBlurInput,
  selectedPayment,
}) {
  const [payments, setPayments] = useState(data);
  const [expended, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    handleOpenAcordion(Number.parseInt(panel[6]), isExpanded);
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpenAcordion = (index, status) => {
    let newPayments = [...payments];

    newPayments.map((pay) => (pay.active = false));
    newPayments.map((pay, indx) =>
      indx !== index ? pay.type.map((type) => (type.active = false)) : ""
    );
    newPayments[index].active = status;

    const event = {
      target: {
        value: newPayments[index].name,
        name: newPayments[index]._id,
      },
    };
    ToggleOnClickPayment(event);
    setPayments(newPayments);
  };

  useEffect(() => {
    setPayments(data);
  }, [data]);

  const changeFor = TranslatorFunction({ path: 'changeFor.message' });
  const language = TranslatorFunction({ path: "language.message" })

  return (
    <Container>
      <RadioGroup arial-label="gender" name="gender1">
        {payments?.map((dt, indx) => (
          <Accordion
            expanded={expended === `painel${indx}`}
            onChange={handleChange(`painel${indx}`)}
            key={indx}
          >
            <AccordionSummary expandIcon={<ReactSVG src={ExpandIcon} />}>
              <FormControlLabel
                onClick={ToggleOnClickPayment}
                onFocus={(event) => event.stopPropagation()}
                control={<GreenRadio />}
                value={dt.name}
                label= {dt.name === "Pix" ?  dt.name : dt.translatedName[0][language] }
                name={dt._id}
                checked={dt.active}
              />
            </AccordionSummary>
            <RadioGroup
              arial-label="gender"
              name="gender2"
              className="helpfull"
            >
              {dt.name !== "Dinheiro" &&
                dt.type.map((tp, index) => (
                  <AccordionDetails key={index * 10}>
                    <FormControlLabel
                      onClick={ToggleOnClickType}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <GreenRadio
                          checked={selectedPayment.type === tp.name}
                        />
                      }
                      value={tp.name}
                      label={tp.name}
                      checked={tp.active}
                    />
                  </AccordionDetails>
                ))}
              {dt.name === "Dinheiro" && (
                <div className="helpfull">
                  <Input
                    onChange={ToggleOnClickInput}
                    value={dt.payback}
                    label={changeFor}
                    onBlur={ToggleOnBlurInput}
                    name="money"
                  />
                </div>
              )}
            </RadioGroup>
          </Accordion>
        ))}
      </RadioGroup>
    </Container>
  );
}

export default AccordionComponent;
