import React from "react";
import { PropTypes } from "prop-types";
import ButtonStyled from "./ButtonStyles";

const Button = (props) => {
  const { children, type, disabled, onClick } = props;

  return (
    <ButtonStyled type={type} disabled={disabled} onClick={onClick}>
      {children}
    </ButtonStyled>
  );
};

Button.defaultProps = {
  type: "primary",
  children: "",
  disabled: false,
  onClick: () => {},
};

Button.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  disabled: PropTypes.bool,
  children: PropTypes.string,
  onClick: PropTypes.func,
};

export default Button;
