import React from 'react';
import PropTypes from 'prop-types';

import { Container, LabelStyle } from './BadgeStyles';

const Badge = ({ children }) => (
  <Container>
    <LabelStyle>
      {children}
    </LabelStyle>
  </Container>
);

Badge.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Badge;
