export default {
  translations: {

    language: {
      message: 'en',
    },
    welcomeToOurDigitalMenu: {
      message: 'Welcome to our digital menu',
    },
    beWelcome: {
      message: 'well come',
    },
    hello: {
      message: 'Hello',
    },
    comments: {
      message: 'Comments',
    },
    selectUntil: {
      message: 'Select until',
    },
    optionsYouSelected: {
      message: ' options. You selected',
    },
    youMustAddAllRequiredComplements: {
      message: 'You must add all required complements.',
    },
    youMustQuantityRequired: {
      message: 'You must add at least 1 product.',
    },
    cartQuantity: {
      message: 'Qty',
    },
    cartItem: {
      message: 'Item',
    },
    cartValue: {
      message: 'Value',
    },
    myOrder: {
      message: 'MY ORDER',
    },
    emptyCart: {
      message: 'EMPTY CART',
    },
    addMoreItems: {
      message: 'ADD MORE ITEMS',
    },
    subtotal: {
      message: 'SUBTOTAL',
    },
    total: {
      message: 'TOTAL',
    },
    formOfPayment: {
      message: 'FORM OF PAYMENT',
    },
    deliveryFee: {
      message: 'Delivery fee',
    },
    deliveryIn: {
      message: 'Delivery in',
    },
    sendOrder: {
      message: 'SEND ORDER',
    },
    ourResponseTimeIsUpTo: {
      message: 'Our reponse time is up to ',
    },
    minutes: {
      message: 'minutes',
    },
    back: {
      message: 'BACK',
    },
    backToMenu: {
      message: 'BACK TO MENU',
    },
    confirmAddress: {
      message: 'CONFIRM ADDRESS',
    },
    confirmTakeAway: {
      message: 'CONFIRM TAKE AWAY',
    },
    invalidCoupon: {
      message: 'Invalid coupon.',
    },
    discountApplied: {
      message: 'Discount applied.',
    },
    discount: {
      message: 'DISCOUNT'
    },
    youMustAskForChangeForAnAmountGreaterThan: {
      message: 'You must ask for change for an amount greater than',
    },
    checkAllFields: {
      message: 'Check all fields.',
    },
    pleaseSelectAProductBeforeProceeding: {
      message: 'Please select a product before proceeding',
    },
    selectAPaymentMethod: {
      message: 'Select a payment method',
    },
    chooseOneOfTheOptions: {
      message: 'Choose one of the options',
    },
    catalog: {
      message: 'Catalog',
    },
    deliveryTakeAway: {
      message: 'Delivery | Take Away',
    },
    localConsumption: {
      message: 'Local consumption',
    },
    simpleEasyFastDigital: {
      message: 'Simple. Easy. Fast. Digital.',
    },
    imagesAreForIllustrationPurposesOnly: {
      message: 'Images are for illustration purposes only.',
    },
    changeFor: {
      message: 'Change for',
    },
    productImage: {
      message: 'Product image',
    },
    weAreNotOpenYet: {
      message: 'We are not open yet',
    },
    checkOur: {
      message: 'Check our',
    },
    openingHours: {
      message: 'opening hours',
    },
    accessMenu: {
      message: 'ACCESS MENU',
    },
    oops: {
      message: 'Oops',
    },
    browse: {
      message: 'BROWSE',
    },
    theHighlights: {
      message: 'THE HIGHLIGHTS',
    },
    helloHowAreYou: {
      message: 'Hello, how are you?',
    },
    forNowOurMenuCanOnlyBeAccessedOnMobilePhones: {
      message: 'For now, our menu can only be accessed on mobile phones.',
    },
    weAreAlreadyWorkingToBringYouTheBestOnlineMenuExperienceAccessibleFromAnyDevice: {
      message: 'We are already working to bring you the best online menu experience accessible from any device.',
    },
    identification: {
      message: 'IDENTIFICATION',
    },
    mandatoryFilling: {
      message: 'mandatory filling',
    },
    name: {
      message: 'Name',
    },
    phone: {
      message: 'Phone',
    },
    cityTakeAway: {
      message: 'City/Take Away',
    },
    street: {
      message: 'Street',
    },
    neighborhoods: {
      message: 'Neighborhoods',
    },
    number: {
      message: 'Number',
    },
    complement: {
      message: 'Complement',
    },
    reference: {
      message: 'Reference',
    },
    coupon: {
      message: 'COUPON',
    },
    add: {
      message: 'ADD',
    },
    typeWhatYouAreLookingFor: {
      message: 'Type what you are looking for...',
    },
    goodThatYouAreHere: {
      message: 'Good that you are here',
    },
    chooseTheItemYouWantAnd: {
      message: 'Choose the item you want and',
    },
    toPlaceYourOrder: {
      message: 'to place your order',
    },
    callAnAttendant: {
      message: ' call an attendant ',
    },
    SUN: {
      message: 'SUN',
    },
    MON: {
      message: 'MON',
    },
    TUES: {
      message: 'TUES',
    },
    WED: {
      message: 'WED',
    },
    THU: {
      message: 'THU',
    },
    FRI: {
      message: 'FRI',
    },
    SAT: {
      message: 'SAT',
    },
    from: {
      message: "FROM"
    },
    to: {
      message: "to"
    },
    hum: {
      message: 'Hmmm',
    },
    nothave: {
      message: "looks like we don't have it yet"
    },
    ismenu: {
      message: "It's on our menu..."
    },
    Additional: {
      message: "Additional"
    },
    takeAway: {
      message: "takeaway"
    },
  },
};
