export const ProductForm = {
  productId: "",
  priceProduct: 0,
  priceProductTotal: 0,
  complementGroup: [],
  observation: "",
  quantity: 0,
};

export const ComplementGroupForm = {
  id: "",
  complements: [],
};

export const ComplementForm = {
  id: "",
  quantity: 0,
  price: 0,
};
