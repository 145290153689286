export default {
  translations: {
    language: {
      message: 'ar',
    },
    welcomeToOurDigitalMenu: {
      message: 'مرحبا بكم في قائمتنا الرقمية',
    },
    beWelcome: {
      message: 'أهلا بك',
    },
    hello: {
      message: 'مرحبًا',
    },
    comments: {
      message: 'تعليقات',
    },
    selectUntil: {
      message: 'حدد حتى',
    },
    optionsYouSelected: {
      message: ' والخيارات. اخترتها',
    },
    youMustAddAllRequiredComplements: {
      message: 'يجب عليك إضافة جميع الوظائف الإضافية المطلوبة. ',
    },
    youMustQuantityRequired: {
      message: 'يجب عليك إضافة منتج واحد على الأقل.',
    },
    myOrder: {
      message: 'طلبي',
    },
    emptyCart: {
      message: 'عربة فارغة',
    },
    addMoreItems: {
      message: 'أضف المزيد من العناصر',
    },
    subtotal: {
      message: 'المجموع الفرعي',
    },
    total: {
      message: 'المجموع',
    },
    formOfPayment: {
      message: 'شكل الدفع',
    },
    deliveryFee: {
      message: 'رسوم التوصيل',
    },
    deliveryIn: {
      message: 'التسليم في',
    },
    sendOrder: {
      message: 'ارسل طلب',
    },
    ourResponseTimeIsUpTo: {
      message: 'وقت ردنا يصل إلى ',
    } ,
    minutes: {
      message: 'الدقائق',
    } ,
    back: {
      message: 'عد',
    },
    backToMenu: {
      message: 'رجوع إلى القائمة',
    },
    confirmAddress: {
      message: 'تأكيد العنوان',
    },
    confirmTakAway: {
      message: 'تأكيد الانسحاب',
    },
    invalidCoupon: {
      message: 'قسيمة غير صالحة.',
    },
    discountApplied: {
      message: 'الخصم قد تم.',
    },
    discount: {
      message: "تخفيض"
    },
    youMustAskForChangeForAnAmountGreaterThan: {
      message: 'يجب أن تطلب التغيير بمبلغ أكبر من',
    },
    checkAllFields: {
      message: 'تحقق من جميع الحقول.',
    },
    pleaseSelectAProductBeforeProceeding: {
      message: 'الرجاء تحديد منتج قبل المتابعة',
    },
    selectAPaymentMethod: {
      message: 'اختر طريقة الدفع',
    },
    chooseOneOfTheOptions: {
      message: 'اختر أحد الخيارات',
    },
    catalog: {
      message: 'فهرس',
    },
    deliveryTakeAway: {
      message: 'توصيل | انسحاب',
    },
    localConsumption: {
      message: 'الاستهلاك المحلي',
    },
    simpleEasyFastDigital: {
      message: 'بسيط. سهل. سريع. رقمي.',
    },
    imagesAreForIllustrationPurposesOnly: {
      message: 'الصور هي لأغراض التوضيح فقط.',
    },
    changeFor: {
      message: 'تغير ل',
    },
    productImage: {
      message: 'صورة المنتج',
    },
    weAreNotOpenYet: {
      message: 'لم نفتح بعد ',
    },
    checkOur: {
      message: 'تحقق من',
    },
    openingHours: {
      message: 'ساعات العمل',
    },
    accessMenu: {
      message: 'قائمة الوصول',
    },
    oops: {
      message: 'وجه الفتاة',
    },
    browse: {
      message: 'التنقل',
    },
    theHighlights: {
      message: 'من خلال النقاط البارزة',
    },
    helloHowAreYou: {
      message: 'مرحبًاّ! كيف حالك؟',
    },
    forNowOurMenuCanOnlyBeAccessedOnMobilePhones: {
      message: "في الوقت الحالي ، لا يمكن الوصول إلى قائمتنا إلا عبر الهواتف المحمولة.",
    } ,
    weAreAlreadyWorkingToBringYouTheBestOnlineMenuExperienceAccessibleFromAnyDevice: {
      message: 'نحن نعمل بالفعل على تقديم أفضل تجربة قائمة على الإنترنت يمكن الوصول إليها من أي جهاز. ',
    } ,
    identification: {
      message: 'هوية',
    },
    mandatoryFilling: {
      message: 'ملء إلزامي',
    },
    name: {
      message: 'اسم',
    },
    phone: {
      message: 'هاتف',
    },
    cityTakeAway: {
      message: 'المدينة / الانسحاب',
    },
    street: {
      message: 'طريق',
    },
    neighborhoods: {
      message: 'أحياء',
    },
    number: {
      message: 'رقم',
    },
    complement: {
      message: 'مديح',
    },
    reference: {
      message: 'المرجعي',
    },
    coupon: {
      message: 'قسيمة',
    },
    add: {
      message: 'يضيف',
    },
    typeWhatYouAreLookingFor: {
      message: 'اكتب ما تبحث عنه ...',
    },
    goodThatYouAreHere: {
      message: 'جيد أنك هنا',
    },
    chooseTheItemYouWantAnd: {
      message: 'اختر العنصر الذي تريده و',
    },
    toPlaceYourOrder: {
      message: 'لوضع طلبك',
    },
    callAnAttendant: {
      message: 'استدعاء أحد المصاحبات',
    },
    cartQuantity: {
      message: 'الكمية',
    },
    cartItem: {
      message: 'العنصر',
    },
    cartValue: {
      message: 'القيمة',
    },
    SUN: {
      message: 'الشمس',
    },
    MON: {
      message: 'إِثْنَيْنِِ',
    },
    TUES: {
      message: 'الثَّلاَثَاءِ',
    },
    WED: {
      message: 'الأَرْبِعَاءِ',
    },
    THU: {
      message: 'الخَمِيْسِ',
    },
    FRI: {
      message: 'الجُمْعَةِ',
    },
    SAT: {
      message: 'السَّبْتِ',
    },
    from: {
      message: "في"
    },
    to: {
      message: "حتى"
    },
    hum: {
      message: 'أمم',
    },
    nothave: {
      message: "يبدو أننا لا نملكها بعد"
    },
    ismenu: {
      message: "إنه في قائمتنا ..."
    },
    Additional: {
      message: "إضافي"
    },
    takeAway: {
      message: "يبعد"
    },
  },
};
