import React from 'react';
import { useTranslation } from 'react-i18next';
import { DivI18n } from './Styles';
import './style.css';
import { Dropdown} from 'react-dropdown-now';
import 'react-dropdown-now/style.css';
import { BrasilFlag, 
  EuaFlag, 
  EspanhaFlag,
  Flag_of_Germany, 
  Flag_of_Italy,
  Flag_of_Japan, 
  Flag_of_Portugal,
  Flag_of_Russia, 
  Flag_of_Saudi_Arabia,
  Flag_of_South_Korea, 
  Flag_of_China,
  Flag_of_France,
} from '../../assets/i18n'

const I18n = () => {
  const { i18n } = useTranslation(); 

  function serchId() {
    let languageID = localStorage.getItem('id')
    if(languageID === null){
      let language = localStorage.getItem('i18nextLng')
      switch (language) {
        case 'pt-BR':
          return 0;
        case 'en-US':
          return 1;
        case 'es-ES':
          return 2;
        case 'pt':
          return 3;
        case 'de':
          return 4;
        case 'it':
          return 5;
        case 'ja':
          return 6;
        case 'zh-TW':
          return 7;
        case 'ko':
          return 8;
        case 'ru':
          return 9;
        case 'ar':
          return 10;
        case 'fr':
          return 11;
        default:
          return 0;
      }
    }
    return languageID;
  }

  function handleChangeLanguage(language) {
    i18n.changeLanguage(language);
  }

  const handleSelectChange = options =>{
    localStorage.setItem('id', options.id)
    handleChangeLanguage(options.value)
    window.location.reload(); 
  }
  
  const  optionsLinguagens = [
    { value: 'pt-BR', label: <img className='flag' src={BrasilFlag}           height="15px" width="20px" alt="Bandeira do Brasil" />, id: '0', className: 'linguagemOptions' },
    { value: 'en-US', label: <img className='flag' src={EuaFlag}              height="15px" width="20px" alt="Bandeira Do EUA"/>, id: '1', className: 'linguagemOptions' },
    { value: 'es-ES', label: <img className='flag' src={EspanhaFlag}          height="15px" width="20px" alt="Bandeira da Espanha"/>, id: '2', className: 'linguagemOptions' },
    { value: 'pt'   , label: <img className='flag' src={Flag_of_Portugal}     height="15px" width="20px" alt="Bandeira de Protugal"/>, id: '3', className: 'linguagemOptions' },
    { value: 'de'   , label: <img className='flag' src={Flag_of_Germany}      height="15px" width="20px" alt="Bandeira da Alemanhã"/>, id: '4', className: 'linguagemOptions' },
    { value: 'it'   , label: <img className='flag' src={Flag_of_Italy}        height="15px" width="20px" alt="Bandeira da Italia"/>, id: '5', className: 'linguagemOptions' },
    { value: 'ja'   , label: <img className='flag border' src={Flag_of_Japan} height="15px" width="20px" alt="Bandeira do Japão"/>, id: '6', className: 'linguagemOptions' },
    { value: 'zh-TW', label: <img className='flag' src={Flag_of_China}        height="15px" width="20px" alt="Bandeira da China"/>, id: '7', className: 'linguagemOptions' },
    { value: 'ko'   , label: <img className='flag border' src={Flag_of_South_Korea}  height="15px" width="20px" alt="Bandeira da Coreia do sul"/>, id: '8', className: 'linguagemOptions' },
    { value: 'ru'   , label: <img className='flag' src={Flag_of_Russia}       height="15px" width="20px" alt="Bandeira da Russia"/>, id: '9', className: 'linguagemOptions' },
    { value: 'ar'   , label: <img className='flag' src={Flag_of_Saudi_Arabia} height="15px" width="20px" alt="Bandeira da Arabia Saudita"/>, id:'10', className: 'linguagemOptions' },
    { value: 'fr'   , label: <img className='flag' src={Flag_of_France} height="15px" width="20px" alt="Bandeira da França"/>, id:'11', className: 'linguagemOptions' },
  ];
  
  return (
    <DivI18n>
      <Dropdown
      id ="language"
      value= {optionsLinguagens[serchId()]}
      className="linguagemSelect"
      options={optionsLinguagens}
      
      onChange={(options) =>  handleSelectChange(options)}
      />
    </DivI18n>
    
  );
};

export default I18n;
