import styled from "styled-components";

export const Container = styled.div`
  width: 90%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  margin-top: 120px;
  margin-bottom: 44px;

  h1 {
    font-weight: 300;
    line-height: 120%;
    font-size: 32px;
    text-align: center;
  }
`;

export const SubtitleWrapper = styled.div`
  margin: 50px 0;

  h2 {
    font-weight: 300;
    line-height: 120%;
    font-size: 24px;
    text-align: center;
    b {
      color: #565656;
      font-weight: 500;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  width: 60%;
  h3 {
    font-weight: 500;
    line-height: 120%;
    font-size: 17px;
    text-align: center;
    color: white;
  }
`;
