export default {
  translations: {
    language: {
      message: 'ru',
    },
    welcomeToOurDigitalMenu: {
      message: 'Добро пожаловать в наше цифровое меню',
    },
    beWelcome: {
      message: 'добро пожаловать',
    },
    hello: {
      message: 'Привет',
    },
    comments: {
      message: 'Комментарии',
    },
    selectUntil: {
      message: 'выбирать до',
    },
    optionsYouSelected: {
      message: ' опции. вы выбрали',
    },
    youMustAddAllRequiredComplements: {
      message: 'Вы должны добавить все необходимые дополнения.',
    },
    youMustQuantityRequired: {
      message: 'Вы должны добавить хотя бы 1 товар.',
    },
    myOrder: {
      message: 'МОЙ ЗАПРОС',
    },
    emptyCart: {
      message: 'ПУСТАЯ ТЕЛЕЖКА',
    },
    addMoreItems: {
      message: 'ДОБАВИТЬ БОЛЬШЕ ТОВАРОВ',
    },
    subtotal: {
      message: 'ПРОМЕЖУТОЧНЫЙ ИТОГ',
    },
    total: {
      message: 'ФОРМА ОПЛАТЫ',
    },
    cartQuantity: {
      message: 'кол-во',
    },
    cartItem: {
      message: 'вещь',
    },
    cartValue: {
      message: 'ценность',
    },
    formOfPayment: {
      message: 'ФОРМА ОПЛАТЫ',
    },
    deliveryFee: {
      message: 'Стоимость доставки',
    },
    deliveryIn: {
      message: 'доставка в',
    },
    sendOrder: {
      message: 'ENVIAR ЗАПРОС',
    },
    ourResponseTimeIsUpTo: {
      message: 'Наше время ответа до',
    },
    minutes: {
      message: 'минуты',
    },
    back: {
      message: 'ВЕРНИСЬ',
    },
    backToMenu: {
      message: 'НАЗАД К МЕНЮ',
    },
    confirmAddress: {
      message: 'ПОДТВЕРДИТЕ АДРЕС',
    },
    confirmTakAway: {
      message: 'Недействительный купон.',
    },
    invalidCoupon: {
      message: 'Недействительный купон.',
    },
    discountApplied: {
      message: 'Скидка применена.',
    },
    discount: {
      message: "СКИДКА"
    },
    youMustAskForChangeForAnAmountGreaterThan: {
      message: 'Вы должны запросить сдачу на сумму, превышающую',
    },
    checkAllFields: {
      message: 'Проверьте все поля.',
    },
    pleaseSelectAProductBeforeProceeding: {
      message: 'Пожалуйста, выберите продукт, прежде чем продолжить',
    },
    selectAPaymentMethod: {
      message: 'Выберите способ оплаты',
    },
    chooseOneOfTheOptions: {
      message: 'Выберите один из вариантов',
    },
    catalog: {
      message: 'Каталог',
    },
    deliveryTakeAway: {
      message: 'Доставка | вывод',
    },
    localConsumption: {
      message: 'Местное потребление',
    },
    simpleEasyFastDigital: {
      message: 'Простой. Легкий. Быстро. Цифровой.',
    },
    imagesAreForIllustrationPurposesOnly: {
      message: 'Изображения только для иллюстрации.',
    },
    changeFor: {
      message: 'изменить для',
    },
    productImage: {
      message: 'Изображение продукта',
    },
    weAreNotOpenYet: {
      message: 'мы еще не открыты',
    },
    checkOur: {
      message: 'Проверьте наши',
    },
    openingHours: {
      message: 'часы работы',
    },
    accessMenu: {
      message: 'ДОСТУП МЕНЮ',
    },
    oops: {
      message: 'ой',
    },
    browse: {
      message: 'НАВИГАЦИЯ',
    },
    theHighlights: {
      message: 'ПО ОСНОВНЫМ',
    },
    helloHowAreYou: {
      message: 'Привет, как дела?',
    },
    forNowOurMenuCanOnlyBeAccessedOnMobilePhones: {
      message: 'На данный момент наше меню доступно только на мобильных телефонах.',
    },
    weAreAlreadyWorkingToBringYouTheBestOnlineMenuExperienceAccessibleFromAnyDevice: {
      message: 'Мы уже работаем над тем, чтобы предоставить лучшее онлайн-меню, доступное с любого устройства.',
    },
    identification: {
      message: 'УДОСТОВЕРЕНИЕ ЛИЧНОСТИ',
    },
    mandatoryFilling: {
      message: 'обязательное заполнение',
    },
    name: {
      message: 'Имя',
    },
    phone: {
      message: 'телефон',
    },
    cityTakeAway: {
      message: 'Город/Вывод',
    },
    street: {
      message: 'Дорога',
    },
    neighborhoods: {
      message: 'окрестности',
    },
    number: {
      message: 'Число',
    },
    complement: {
      message: 'Дополнение',
    },
    reference: {
      message: 'Ссылка',
    },
    coupon: {
      message: 'КУПОН',
    },
    add: {
      message: 'ДОБАВЛЯТЬ',
    },
    typeWhatYouAreLookingFor: {
      message: 'Введите то, что вы ищете...',
    },
    goodThatYouAreHere: {
      message: 'хорошо, что ты здесь',
    },
    chooseTheItemYouWantAnd: {
      message: 'Выберите нужный товар и',
    },
    toPlaceYourOrder: {
      message: 'разместить заказ',
    },
    callAnAttendant: {
      message: 'استدعاء أحد المصاحبات',
    },
    SUN: {
      message: 'ВС',
    },
    MON: {
      message: 'ПН',
    },
    TUES: {
      message: 'ВТ',
    },
    WED: {
      message: 'СР',
    },
    THU: {
      message: 'ЧТ',
    },
    FRI: {
      message: 'ПТ',
    },
    SAT: {
      message: 'СБ',
    },
    from: {
      message: "В"
    },
    to: {
      message: "ВВЕРХ ДО"
    },
    hum: {
      message: 'хм',
    },
    nothave: {
      message: "похоже, у нас его еще нет"
    },
    ismenu: {
      message: "Он есть в нашем меню..."
    },
    Additional: {
      message: "Дополнительный"
    },
    takeAway: {
      message: "Еда на вынос"
    },
  },
};
