import styled from "styled-components";
import { Link } from "react-router-dom";
import defaultImg from "../../../assets/img/productDefaultImg.png";

import ImgAdd from "../../../assets/img/add.svg";

import ImgMenos from "../../../assets/img/menos.svg";

export const PageWrapper = styled.div`
  width: 100%;
  flex: 1;
  background: ${({ theme }) => theme.colors.mainColorContrast};
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
`;

export const ContainerProductDescription = styled.div``;

export const ImageContainer = styled.div`
  height: ${({ img }) => (img ? 244 : 80)}px;
  background-color: ${({ theme }) => theme.colors.mainColor};
  background-image: url(${({ img }) => img || defaultImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const InfoContainer = styled.section`
  position: relative;
  padding: 16px 20px;
  background: ${({ theme }) => theme.colors.mainColorContrast};
  .link-a {
    color: #1a0dab;
  }
`;

export const CategoryName = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  text-transform: uppercase;
`;

export const ProductTitle = styled.h1`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 8px;
`;

export const ProductInfo = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  white-space: pre-line;
`;

export const PriceContainer = styled.div`
  position: absolute;
  top: 1px;
  left: 0px;
  transform: translate(0%, -100%);
  width: 100%;
  height: 16px;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  background: ${({ theme }) => theme.colors.mainColorContrast};
  box-shadow: 0px -16px 16px rgba(0, 0, 0, 0.24);
`;

export const PriceTag = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  background: ${({ theme }) => theme.colors.mainColorDark};
  padding: 8px 24px;
  border-radius: 16px;
  position: absolute;
  top: -8px;
  right: 24px;
  transform: translate(0%, -50%);
`;

export const TagsContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const BackHomeLink = styled(Link)`
  align-self: center;
  width: fit-content;
  height: 48px;
  padding: 14px 16px;

  box-shadow: none;
  outline: none;
  background: none;
  border: none;

  color: ${({ theme }) => theme.colors.mainColor};
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;
`;

export const ComplementGroupContainer = styled.div``;

export const ComplementGroupContext = styled.div`
  margin: 30px 0px 0px 0px;
`;

export const ComplementGroupTitle = styled.h3`
  color: #ff5533;
  margin: 0px 10px 0px 40px;
`;

export const ComplementGroupSubTitle = styled.h5`
  color: #aaa;
  margin: 0px 0px 15px 40px;
  label {
    margin-left: 5px;
    color: #ff5533;
  }
`;

export const DivisionLine = styled.div`
  border: 1px solid #ffdcd5;
  border-width: 1px;
`;

export const ComplementContainer = styled.div`
  margin: 15px 30px 15px 40px;
  display: flex;
  justify-content: space-between;
`;

export const ComplementContext = styled.div`
  h5 {
    color: #4b4b4b;
    margin-bottom: 1px;
  }
  h6 {
    color: #858585;
  }
`;

export const ComplementCount = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    width: 15px;
    border: 0;
    text-align: center;
    color: #858585;
  }
`;

export const ComplementButtonAdd = styled.button`
  /* background-image: url(${ImgAdd}); */
  color: #ff5533 !important;
  background-color: transparent;
  border: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-size: 11px 11px; */
  img {
    width: 9px;
    height: 9px;
    pointer-events: none;
  }
`;

export const ComplementButtonReduce = styled.button`
  /* background-image: url(${ImgMenos}); */
  background-color: transparent;
  border: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    pointer-events: none;
  }
  /* background-size: 15px 15px; */
`;

export const ProductContainer = styled.div``;

export const ProductTittle = styled.h3`
  color: #ff5533;
  margin: 30px 0px 10px 40px;
`;
export const ProductFormContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0px 0px 0px 0px;
`;

export const ProductObsInputText = styled.textarea`
  width: 95%;
  height: 80px;
  margin: 0px 10px 15px 10px;
  border: 1px solid #aaa;
  border-radius: 15px;
  padding: 5px;
  font-size: 11px;
  resize: none;
  color: #aaa;
  :focus {
    outline: 0px;
  }
`;

export const ProductFormCounting = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  margin: 0px 0px 0px 0px;

  input {
    width: 15px;
    border: 0;
    text-align: center;
    color: #858585;
  }
`;
export const ProductFormButton = styled.button`
  display: flex;
  flex-direction: column;
  background-color: #ff5533;
  border-radius: 10px;
  width: 35%;
  border: 0px;
  align-items: center;
  color: #fff;
  font-weight: 600;
  padding: 5px 0px 3px 0px;
  margin: 0px 10px 0px 80px;
  label {
    font-size: 9px;
    color: #fff;
  }
`;

export const FullScreenImage = styled.div`
  display: ${({ fullScreen }) => (fullScreen ? "block" : "none")};
  position: ${({ fullScreen }) => (fullScreen ? "fixed" : "initial")};
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: 100000;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    display: ${({ fullScreen }) => (fullScreen ? "block" : "none")};
    position: ${({ fullScreen }) => (fullScreen ? "fixed" : "initial")};
    right: 12px;
    top: 12px;
  }
`;

export const ImageFullScreen = styled.div`
  height: 80%;
  width: 90%;
  background-image: url(${({ img }) => img || defaultImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
