export default {
  translations: {

    language: {
      message: 'es',
    },
    welcomeToOurDigitalMenu: {
      message: 'Bienvenido a nuestro menú digital',
    },
    beWelcome: {
      message: 'se bienvenido',
    },
    hello: {
      message: 'Hola',
    },
    comments: {
      message: 'Comentarios',
    },
    selectUntil: {
      message: 'Seleccionar hasta',
    },
    optionsYouSelected: {
      message: ' opciones usted seleccionó',
    },
    youMustAddAllRequiredComplements: {
      message: 'Debe agregar todos los complementos requeridos.',
    },
    youMustQuantityRequired: {
      message: 'Debes agregar al menos 1 producto.',
    },
    myOrder: {
      message: 'MI PEDIDO',
    },
    emptyCart: {
      message: 'CARRO VACIO',
    },
    addMoreItems: {
      message: 'AÑADIR MÁS ARTÍCULOS',
    },
    subtotal: {
      message: 'TOTAL PARCIAL',
    },
    cartQuantity: {
      message: 'Ctdad',
    },
    cartItem: {
      message: 'Item',
    },
    cartValue: {
      message: 'Valor',
    },
    total: {
      message: 'TOTAL',
    },
    formOfPayment: {
      message: 'FORMA DE PAGO',
    },
    deliveryFee: {
      message: 'Gastos de envío',
    },
    deliveryIn: {
      message: 'Entrega en',
    },
    sendOrder: {
      message: 'ENVIAR ORDEN',
    },
    ourResponseTimeIsUpTo: {
      message: 'Nuestro tiempo de respuesta es hasta ',
    },
    minutes: {
      message: 'minutos',
    },
    back: {
      message: 'ESPALDA',
    },
    backToMenu: {
      message: 'VOLVER AL MENÚ',
    },
    confirmAddress: {
      message: 'CONFIRMAR DIRECCIÓN',
    },
    confirmTakeAway: {
      message: 'CONFIRMAR PARA LLEVAR',
    },
    invalidCoupon: {
      message: 'Cupón no válido.',
    },
    discountApplied: {
      message: 'Descuento aplicado.',
    },
    discount: {
      message: 'DESCUENTO'
    },
    youMustAskForChangeForAnAmountGreaterThan: {
      message: 'Debe pedir cambio por una cantidad superior a',
    },
    checkAllFields: {
      message: 'Revisa todos los campos.',
    },
    pleaseSelectAProductBeforeProceeding: {
      message: 'Seleccione un producto antes de continuar',
    },
    selectAPaymentMethod: {
      message: 'Seleccione un método de pago',
    },
    chooseOneOfTheOptions: {
      message: 'Elige una de las opciones',
    },
    catalog: {
      message: 'Catalogar',
    },
    deliveryTakeAway: {
      message: 'Entrega | Quitar',
    },
    localConsumption: {
      message: 'Consumo local',
    },
    simpleEasyFastDigital: {
      message: 'Simple. Fácil. Rápido. Digital.',
    },
    imagesAreForIllustrationPurposesOnly: {
      message: 'Las imágenes son sólo para fines ilustrativos.',
    },
    changeFor: {
      message: 'Cambiar para',
    },
    productImage: {
      message: 'Imagen del producto',
    },
    weAreNotOpenYet: {
      message: 'Nosotros no estamos abiertos todavía',
    },
    checkOur: {
      message: 'Revisa nuestro',
    },
    openingHours: {
      message: 'horario de apertura',
    },
    accessMenu: {
      message: 'MENÚ DE ACCESO',
    },
    oops: {
      message: 'Ups',
    },
    browse: {
      message: 'NAVEGAR',
    },
    theHighlights: {
      message: 'LOS ASPECTOS INTERESANTES',
    },
    helloHowAreYou: {
      message: '¿Hola cómo estás?',
    },
    forNowOurMenuCanOnlyBeAccessedOnMobilePhones: {
      message: 'Por ahora, solo se puede acceder a nuestro menú en teléfonos móviles.',
    },
    weAreAlreadyWorkingToBringYouTheBestOnlineMenuExperienceAccessibleFromAnyDevice: {
      message: 'Ya estamos trabajando para brindarte la mejor experiencia de menú en línea accesible desde cualquier dispositivo.',
    },
    identification: {
      message: 'IDENTIFICACIÓN',
    },
    mandatoryFilling: {
      message: 'llenado obligatorio',
    },
    name: {
      message: 'Nombre',
    },
    phone: {
      message: 'Teléfono',
    },
    cityTakeAway: {
      message: 'Ciudad/Para llevar',
    },
    street: {
      message: 'Calle',
    },
    neighborhoods: {
      message: 'barrios',
    },
    number: {
      message: 'Número',
    },
    complement: {
      message: 'Complementar',
    },
    reference: {
      message: 'Referencia',
    },
    coupon: {
      message: 'CUPÓN',
    },
    add: {
      message: 'AGREGAR',
    },
    typeWhatYouAreLookingFor: {
      message: 'Escribe lo que buscas...',
    },
    goodThatYouAreHere: {
      message: 'Bueno que estas aqui',
    },
    chooseTheItemYouWantAnd: {
      message: 'Elige el artículo que quieras y',
    },
    toPlaceYourOrder: {
      message: 'para hacer tu pedido',
    },
    callAnAttendant: {
      message: ' llamar a un asistente ',
    },
    SUN: {
      message: 'DOM',
    },
    MON: {
      message: 'LUN',
    },
    TUES: {
      message: 'MAR',
    },
    WED: {
      message: 'MIE',
    },
    THU: {
      message: 'JUE',
    },
    FRI: {
      message: 'VIE',
    },
    SAT: {
      message: 'SAB',
    },
    rom: {
      message: "DE"
    },
    to: {
      message: "a"
    },
    hum: {
      message: 'mmm',
    },
    nothave: {
      message: "parece que aún no lo tenemos"
    },
    ismenu: {
      message: "Está en nuestro menú..."
    },
    Additional: {
      message: "Adicional"
    },
    takeAway: {
      message: "Quitar"
    },
  },
};
