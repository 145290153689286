import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CategoryList from '../../components/categoryList/CategoryList';
import HighlightsList from '../../components/highlightsList/HighlitghtsList';
import { PageWrapper, TitleWrapper } from './styles';
import restMenus from '../../api/menus/rest-menus';
import { getOperationId } from '../../services/localStorage';
import { scrollToTop } from '../../utils';
import TranslatorFunction from '../../components/I18n/Translator';

function Menu() {
  const [categoriesList, setCategoriesList] = useState([]);
  const [highlightsList, setHighlightsList] = useState([]);
  const { domain } = useParams();
  const enableId = getOperationId();
  const hello = TranslatorFunction({ path: 'hello.message' });
  const beWelcome = TranslatorFunction({ path: 'beWelcome.message' });
  const handleMenusResponse = (response) => {
    const categories = response.data.menuContent;
    sessionStorage.removeItem('subCategory');
    const newsCategories = categories.filter(
      (value) => value.name !== 'Destaques',
    );

    setCategoriesList(newsCategories);
  };

  const fetchMenus = () => restMenus
    .fetchMenus(domain.toLowerCase(), enableId)
    .then(handleMenusResponse);

  const fetchProducts = () => {
    restMenus
      .fetchProductsHighlighted(domain.toLowerCase(), enableId)
      .then(({ data }) => {
        setHighlightsList(data.products);
      });
  };

  useEffect(() => {
    fetchMenus();
    fetchProducts();
    scrollToTop();
    // eslint-disable-next-line
  }, [domain]);
  return (
    <PageWrapper>
      <TitleWrapper>
        <h1>
          {hello}
          ,
        </h1>
        <h2>
          {beWelcome}
          !
        </h2>
      </TitleWrapper>
      <HighlightsList highlights={highlightsList} />
      <CategoryList categories={categoriesList} />
    </PageWrapper>
  );
}

export default Menu;
