import React from "react";
import PropTypes from "prop-types";

import { Container } from "./styles";

import TextField from "@material-ui/core/TextField";

const genRandomIdHash = () => Math.random().toString(36).substr(2, 5);

function Input(props) {
  const {
    label,
    value,
    disabled = false,
    onChange,
    id,
    required,
    name,
    onBlur,
  } = props;

  const handleOnChange = (event) => {
    onChange(event.target.value);
  };

  const handleOnBlur = (event) => {
    onBlur(event.target.value);
  };

  return (
    <Container>
      <TextField
        value={value}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        id={id}
        disabled={disabled}
        pattern=".+"
        required={required}
        name={name ?? ""}
        label={label}
      />
    </Container>
  );
}

Input.defaultProps = {
  disabled: false,
  label: "",
  id: "" || genRandomIdHash(),
  onChange: () => {},
  onBlur: () => {},
  value: "",
  required: false,
};

Input.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  required: PropTypes.bool,
};

export default Input;
