import styled from 'styled-components';

const SearchWrapper = styled.div`
  min-height: calc(80vh - 50px);
  width: 100vw;
  margin-bottom: 24px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
`;

const SearchProductsWrapper = styled.div`
  margin-top: 100px;
`;

const SearchInputFixedContainer = styled.div`
  position: fixed;
  z-index:1000;
  width: 100%;
  left: 0;
  top: 60px;
  padding: 0 16px;
  background-color: ${({ theme }) => (theme.colors.mainColorContrast)};
  margin-bottom: 20px;
  overflow: none;
  ${({ scrollPosition }) => (scrollPosition !== 0 ? 'box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);' : '')}
`;

const SearchInputContainer = styled.div`
  margin-right: 32px;
  margin-bottom: 20px;
  height: 56px;
  margin-top: 8px;
  width: 100%;
  display: flex;
  overflow: none;
  flex-direction: column-reverse;
`;

const LinkContainer = styled.div`
  margin-top: auto;
  height: 100%;
  align-self: center;
`;

export {
  SearchWrapper,
  SearchProductsWrapper,
  SearchInputFixedContainer,
  SearchInputContainer,
  LinkContainer,
};
