import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@material-ui/core";

import { ReactSVG } from "react-svg";

import { Container } from "./styles";
import ExpandIcon from "../../../../assets/img/expandIcon.svg";

function AccordionComponent({ title, data, name, selectedValue, onChange }) {
  return (
    <Container>
      <Accordion>
        <AccordionSummary
          expandIcon={<ReactSVG src={ExpandIcon} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {title}
        </AccordionSummary>
        <AccordionDetails>
          <RadioGroup
            arial-label={title}
            name={name}
            onChange={onChange}
            value={selectedValue}
          >
            {data?.map((data, index) => (
              <FormControlLabel
                key={index}
                value={data.name}
                control={<Radio />}
                label={data.name}
                name={data.id ?? data.price}
              />
            ))}
          </RadioGroup>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

export default AccordionComponent;
