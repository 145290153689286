export default {
  translations: {
    language: {
      message: 'ja',
    },
    welcomeToOurDigitalMenu: {
      message: 'デジタルメニューへようこそ',
    },
    beWelcome: {
      message: 'ようこそ',
    },
    hello: {
      message: 'こんにちは',
    },
    comments: {
      message: 'コメント',
    },
    selectUntil: {
      message: 'まで選択',
    },
    optionsYouSelected: {
      message: ' オプション。 あなたが選択した',
    },
    youMustAddAllRequiredComplements: {
      message: '必要なアドオンをすべて追加する必要があります。',
    },
    youMustQuantityRequired: {
      message: '少なくとも 1 つの製品を追加する必要があります。',
    },
    myOrder: {
      message: '私の要求',
    },
    emptyCart: {
      message: '空のカート',
    },
    addMoreItems: {
      message: 'さらにアイテムを追加',
    },
    subtotal: {
      message: '小計',
    },
    total: {
      message: '合計',
    },
    cartQuantity: {
      message: '数量',
    },
    cartItem: {
      message: 'アイテム',
    },
    cartValue: {
      message: '価値',
    },
    formOfPayment: {
      message: '支払形式',
    },
    deliveryFee: {
      message: 'タッサ ディ コンセーニャ',
    },
    deliveryIn: {
      message: 'での配達',
    },
    sendOrder: {
      message: 'リクエストを送る',
    },
    ourResponseTimeIsUpTo: {
      message: '私たちの応答時間は最大です',
    },
    minutes: {
      message: '分',
    },
    back: {
      message: '戻ってくる',
    },
    backToMenu: {
      message: 'メニューに戻る',
    },
    confirmAddress: {
      message: 'アドレスを確認してください',
    },
    confirmTakAway: {
      message: '出金の確認',
    },
    invalidCoupon: {
      message: 'クーポンが無効です。',
    },
    discountApplied: {
      message: '割引適用.',
    },
    discount: {
      message: "割引"
    },
    youMustAskForChangeForAnAmountGreaterThan: {
      message: '以上の金額の釣り銭を要求する必要があります',
    },
    checkAllFields: {
      message: 'すべてのフィールドをチェック.',
    },
    pleaseSelectAProductBeforeProceeding: {
      message: '続行する前に製品を選択してください',
    },
    selectAPaymentMethod: {
      message: '支払い方法を選んでください',
    },
    chooseOneOfTheOptions: {
      message: 'いずれかのオプションを選択してください',
    },
    catalog: {
      message: 'カタログ',
    },
    deliveryTakeAway: {
      message: '配信 | 撤退',
    },
    localConsumption: {
      message: '地産地消',
    },
    simpleEasyFastDigital: {
      message: '単純。 簡単。 速い。 デジタル.',
    },
    imagesAreForIllustrationPurposesOnly: {
      message: '画像は説明のみを目的としています。',
    },
    changeFor: {
      message: '変更',
    },
    productImage: {
      message: '商品イメージ',
    },
    weAreNotOpenYet: {
      message: '私たちはまだ開いていません',
    },
    checkOur: {
      message: '私たちをチェックしてください',
    },
    openingHours: {
      message: '営業時間',
    },
    accessMenu: {
      message: 'アクセスメニュー',
    },
    oops: {
      message: 'おっとっと',
    },
    browse: {
      message: 'ナビゲート',
    },
    theHighlights: {
      message: 'ハイライト別',
    },
    helloHowAreYou: {
      message: 'こんにちは元気ですか？',
    },
    forNowOurMenuCanOnlyBeAccessedOnMobilePhones: {
      message: '今のところ、私たちのメニューは携帯電話でのみアクセスできます。',
    },
    weAreAlreadyWorkingToBringYouTheBestOnlineMenuExperienceAccessibleFromAnyDevice: {
      message: 'どのデバイスからでもアクセスできる最高のオンライン メニュー エクスペリエンスをお届けできるよう、すでに取り組んでいます。',
    },
    identification: {
      message: '身元',
    },
    mandatoryFilling: {
      message: '必須の記入',
    },
    name: {
      message: '名前',
    },
    phone: {
      message: '電話',
    },
    cityTakeAway: {
      message: '市区町村・出国',
    },
    street: {
      message: '道',
    },
    neighborhoods: {
      message: '近所',
    },
    number: {
      message: '番号',
    },
    complement: {
      message: '補体',
    },
    reference: {
      message: '参照',
    },
    coupon: {
      message: 'クーポン',
    },
    add: {
      message: '追加',
    },
    typeWhatYouAreLookingFor: {
      message: '探しているものを入力してください...',
    },
    goodThatYouAreHere: {
      message: 'あなたがここにいてよかった',
    },
    chooseTheItemYouWantAnd: {
      message: 'ご希望の商品をお選びいただき、',
    },
    toPlaceYourOrder: {
      message: '注文する',
    },
    callAnAttendant: {
      message: 'アテンダントを呼ぶ',
    },
    SUN: {
      message: '日',
    },
    MON: {
      message: '月',
    },
    TUES: {
      message: '火',
    },
    WED: {
      message: '水',
    },
    THU: {
      message: '木',
    },
    FRI: {
      message: '金',
    },
    SAT: {
      message: '土',
    },
    from: {
      message: "の"
    },
    to: {
      message: "まで"
    },
    hum: {
      message: 'うーん',
    },
    nothave: {
      message: "まだ持っていないようです"
    },
    ismenu: {
      message: "当店のメニューです..."
    },
    Additional: {
      message: "追加"
    },
    takeAway: {
      message: "取り除く"
    },
  },
};
