import React, {
  useMemo, useState, useContext,
  useCallback, createContext,
} from 'react';
import PropTypes from 'prop-types';
import BlockLoader from '../components/block-loader/BlockLoader';

const BlockLoadingContext = createContext({});

const useBlockLoadingContext = () => (
  useContext(BlockLoadingContext)
);

const BloackLoadingProvider = ({ children }) => {
  const [isLoading, _setIsLoading] = useState(false);
  const setIsLoading = useCallback((e) => _setIsLoading(e), [_setIsLoading]);
  const value = useMemo(() => ({ isLoading, setIsLoading }), [isLoading, setIsLoading]);
  return (
    <BlockLoadingContext.Provider value={value}>
      <BlockLoader blocking={isLoading}>
        { children }
      </BlockLoader>
    </BlockLoadingContext.Provider>
  );
};

BloackLoadingProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.any,
  ]).isRequired,
};

export {
  BlockLoadingContext,
  useBlockLoadingContext,
};
export default BloackLoadingProvider;
