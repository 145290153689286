import HttpClient from '../http-client';

const fetchMenus = (domain, enableIn) =>
  HttpClient.get(`/menuContent/${domain.toLowerCase()}/${enableIn}`);

const addAccess = (domain) =>
  HttpClient.post(`/menuContent/${domain.toLowerCase()}/access`);

const fecthMenusCategory = (domain, category) =>
  HttpClient.get(`/menuContent/${domain.toLowerCase()}/category/${category}`);

const fecthMenusSubCategory = (domain, category, subcategory, enableIn) =>
  HttpClient.get(
    `/menuContent/${domain.toLowerCase()}/${category}/${subcategory}/${enableIn}`
  );

const fetchProductByOperation = (domain, enableIn) =>
  HttpClient.get(`/menuContent/${domain.toLowerCase()}/products/${enableIn}`);

const fetchProductsHighlighted = (domain, enableIn) =>
  HttpClient.get(
    `/menuContent/${domain.toLowerCase()}/productsHighlighted/${enableIn}`
  );

const fetchOperations = (domain) =>
  HttpClient.get(`/menuContent/${domain.toLowerCase()}/operations`);

const fetchPaymentMethods = (domain) =>
  HttpClient.get(`/menuContent/${domain.toLowerCase()}/paymentMethod`);

const fetchMandatoryData = (domain) =>
  HttpClient.get(`/menuContent/${domain.toLowerCase()}/mandatoryData`);

const fetchSchedules = (domain) =>
  HttpClient.get(`/menuContent/${domain.toLowerCase()}/schedules`);

const postOrder = (domain, data) => {
  return HttpClient.post(`/menuContent/${domain.toLowerCase()}/order`, data);
};

const verifyCoupon = (domain, coupon) => {
  return HttpClient.post(`/menuContent/${domain.toLowerCase()}/couponisvalid`, {
    couponCode: coupon,
  });
};

const fetchPixelFacebook = (domain) => {
  return HttpClient.get(`/menuContent/${domain.toLowerCase()}/facebook`);
};

export default {
  fetchMenus,
  fecthMenusCategory,
  fecthMenusSubCategory,
  fetchProductsHighlighted,
  fetchOperations,
  fetchPaymentMethods,
  fetchMandatoryData,
  fetchSchedules,
  postOrder,
  fetchProductByOperation,
  verifyCoupon,
  fetchPixelFacebook,
  addAccess,
};
