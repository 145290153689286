import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { uniqBy } from "lodash";

import InputText from "../../components/inputs/input-text/InputText";
import Link from "../../components/link/Link";
import {
  SearchWrapper,
  SearchInputContainer,
  LinkContainer,
  SearchInputFixedContainer,
  SearchProductsWrapper,
} from "./SearchStyles";
import SearchResults from "./searchResults/SearchResults";
import restMenus from "../../api/menus/rest-menus";
import { getOperationId } from "../../services/localStorage";
import TranslatorFunction from "../../components/I18n/Translator"

const filterProductsByText = (product, text) => {
  return product.name.toLowerCase().trim().includes(text.toLowerCase().trim());
};

const categoriesMapFunciton = (products) => {
  try{
    const formattedProductsByCategory = products
    .flatMap((prod) => prod.subcategories.map((sub) => sub.category.name))
    .map((category) => ({
      category,
      products: products.filter((product) =>
        product.subcategories.find((sub) => sub.category.name === category)
      ),
    }));

  const formattedProducts = uniqBy(formattedProductsByCategory, "category");
  return formattedProducts.map((prod) => (
  
    <SearchResults
      products={prod.products}
      categoryName={prod.category}
      key={products._id}
    />
  ));
  }
  catch(e){
    console.log(e)
    return null;
  }
  
};

const renderEmptySearchResults = () => (
  <SearchResults products={[]} categoryName="" />
);

const renderProductsList = (products) => (
  <SearchProductsWrapper>
    {products.length === 0
      ? renderEmptySearchResults()
      : categoriesMapFunciton(products)}
  </SearchProductsWrapper>
);

const SearchPage = () => {
  const {pathname} = useLocation();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [products, setProducts] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { domain } = useParams();
  const enableIn = getOperationId();
  const history = useHistory();

  const typeWhatYouAreLookingFor = TranslatorFunction({ path: 'typeWhatYouAreLookingFor.message' });
  const backToMenu = TranslatorFunction({ path: 'backToMenu.message' });

  const filterCategoriesAndProducts = (text) =>
    products.filter((content) => filterProductsByText(content, text));

  const getProducts = async () => {
    restMenus
      .fetchProductByOperation(domain, enableIn)
      .then((response) => {
        const { data } = response;
        setProducts(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initSearch = (text) => {
    setFilteredCategories(filterCategoriesAndProducts(text));
  };

  const handleInputChange = (text) => {
    setSearchText(text);
    if (text.length >= 1) {
      initSearch(text);
    } else {
      setFilteredCategories([]);
    }
  };

  const goToMenu = () => {
    const link = pathname.split('/')[1];

    history.push(`/${link}/delivery/menu`)
  }

  useEffect(() => {
    document.addEventListener("scroll", () =>
      setScrollPosition(document.documentElement.scrollTop)
    );
  }, []);

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <SearchInputFixedContainer scrollPosition={scrollPosition}>
        <SearchInputContainer>
          <InputText
            value={searchText}
            label={typeWhatYouAreLookingFor}
            onChange={handleInputChange}
          />
        </SearchInputContainer>
      </SearchInputFixedContainer>
      <SearchWrapper>
        {searchText.length >= 1 ? renderProductsList(filteredCategories) : null}
        <LinkContainer>
          <Link onClick={goToMenu}>{backToMenu}</Link>
        </LinkContainer>
      </SearchWrapper>
    </>
  );
};

export default SearchPage;
