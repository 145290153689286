import styled, { keyframes } from "styled-components";
import { ReactSVG } from "react-svg";

const List = styled.section`
  padding-left: 16px;
  margin-top: 32px;
`;

const slideAnimation = keyframes`
  0% { transform: translate(100vh, 0%) }
  100% { transform: translate(0%, 0%) }
`;

const ListItem = styled.div`
  position: relative;
  padding-left: 36px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px 0px 0px 16px;
  margin-bottom: 16px;
  background: ${({ theme }) => theme.colors.mainColorContrast};
  overflow: hidden;

  animation-name: ${slideAnimation};
  transform: translate(100vh, 0%);
  animation-fill-mode: forwards;
  animation-duration: ${({ index }) => 0.5 + index / 20}s;

  p {
    padding: 24px 0px;
    padding-right: 12px;
    max-width: 60%;
    font-weight: 300;
    font-size: 16px;
    line-height: 120%;
    text-transform: uppercase;
  }
`;

const skewDeg = -16;

const FoodImg = styled.div`
  position: absolute;
  top: 0px;
  right: -16px;
  height: 100%;
  width: 132px;
  max-width: 40%;
  border-top-left-radius: 16px;
  transform: skew(${skewDeg}deg);
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    background-image: url(${({ img }) => img});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 0px;
    right: -4px;
    width: 148px;
    height: 100%;
    transform: skew(${-skewDeg}deg);
  }
`;

const Arrow = styled(ReactSVG)`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translate(0%, -50%);

  svg path {
    fill: ${({ theme, secondary }) =>
      secondary ? theme.colors.mainColorContrast : theme.colors.mainColor};
  }
`;

export { List, ListItem, Arrow, FoodImg };
