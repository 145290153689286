export default {
  translations: {
    language: {
      message: 'ko',
    },
    welcomeToOurDigitalMenu: {
      message: '디지털 메뉴에 오신 것을 환영합니다',
    },
    beWelcome: {
      message: '어서 오십시오',
    },
    hello: {
      message: '안녕하십니까',
    },
    comments: {
      message: '코멘트',
    },
    selectUntil: {
      message: '까지 선택',
    },
    optionsYouSelected: {
      message: ' 옵션. 당신이 선택',
    },
    youMustAddAllRequiredComplements: {
      message: '필요한 모든 추가 기능을 추가해야 합니다.',
    },
    youMustQuantityRequired: {
      message: '제품을 1개 이상 추가해야 합니다.',
    },
    myOrder: {
      message: '내 요청',
    },
    emptyCart: {
      message: '빈 카트',
    },
    addMoreItems: {
      message: '더 많은 항목 추가',
    },
    subtotal: {
      message: '소계',
    },
    total: {
      message: '총',
    },
    cartQuantity: {
      message: '수량',
    },
    cartItem: {
      message: '안건',
    },
    cartValue: {
      message: '값',
    },
    formOfPayment: {
      message: '지불 형태',
    },
    deliveryFee: {
      message: '운송비',
    },
    deliveryIn: {
      message: '배달',
    },
    sendOrder: {
      message: '요청 보내기',
    },
    ourResponseTimeIsUpTo: {
      message: '우리의 응답 시간은 ',
    },
    minutes: {
      message: '분',
    },
    back: {
      message: '돌아와',
    },
    backToMenu: {
      message: '메뉴로 돌아가기',
    },
    confirmAddress: {
      message: '주소 확인',
    },
    confirmTakAway: {
      message: '철회 확인',
    },
    invalidCoupon: {
      message: '유효하지 않은 쿠폰입니다.',
    },
    discountApplied: {
      message: '할인이 적용되었습니다.',
    },
    discount: {
      message: "할인"
    },
    youMustAskForChangeForAnAmountGreaterThan: {
      message: '다음보다 큰 금액으로 변경을 요청해야 합니다.',
    },
    checkAllFields: {
      message: '모든 필드를 확인하십시오.',
    },
    pleaseSelectAProductBeforeProceeding: {
      message: '계속하기 전에 제품을 선택하십시오',
    },
    selectAPaymentMethod: {
      message: '결제 방법을 선택',
    },
    chooseOneOfTheOptions: {
      message: '옵션 중 하나를 선택하십시오',
    },
    catalog: {
      message: '목록',
    },
    deliveryTakeAway: {
      message: '배달 | 철수',
    },
    localConsumption: {
      message: '현지 소비',
    },
    simpleEasyFastDigital: {
      message: '단순한. 쉬운. 빠른. 디지털.',
    },
    imagesAreForIllustrationPurposesOnly: {
      message: '이미지는 설명을 위한 것입니다.',
    },
    changeFor: {
      message: '위해 바꿉니다',
    },
    productImage: {
      message: '제품 이미지',
    },
    weAreNotOpenYet: {
      message: '우리는 아직 열리지 않았습니다',
    },
    checkOur: {
      message: '우리의',
    },
    openingHours: {
      message: '개관 시간',
    },
    accessMenu: {
      message: '액세스 메뉴',
    },
    oops: {
      message: '죄송합니다',
    },
    browse: {
      message: '탐색',
    },
    theHighlights: {
      message: '하이라이트',
    },
    helloHowAreYou: {
      message: '안녕하세요. 어떻게 지내세요?',
    },
    forNowOurMenuCanOnlyBeAccessedOnMobilePhones: {
      message: '현재 저희 메뉴는 휴대폰에서만 이용하실 수 있습니다.',
    },
    weAreAlreadyWorkingToBringYouTheBestOnlineMenuExperienceAccessibleFromAnyDevice: {
      message: '우리는 이미 모든 장치에서 액세스할 수 있는 최고의 온라인 메뉴 경험을 제공하기 위해 노력하고 있습니다.',
    },
    identification: {
      message: '신분증',
    },
    mandatoryFilling: {
      message: '필수 채우기',
    },
    name: {
      message: '이름',
    },
    phone: {
      message: '전화',
    },
    cityTakeAway: {
      message: '도시/철수',
    },
    street: {
      message: '도로',
    },
    neighborhoods: {
      message: '이웃',
    },
    number: {
      message: '숫자',
    },
    complement: {
      message: '보어',
    },
    reference: {
      message: '참조',
    },
    coupon: {
      message: '쿠폰',
    },
    add: {
      message: '추가하다',
    },
    typeWhatYouAreLookingFor: {
      message: '찾고자 하는 내용을 입력하세요...',
    },
    goodThatYouAreHere: {
      message: '당신이 여기 있어 다행이다',
    },
    chooseTheItemYouWantAnd: {
      message: '원하는 항목을 선택하고',
    },
    toPlaceYourOrder: {
      message: '주문을 하기 위해',
    },
    callAnAttendant: {
      message: '교환원에게 전화를 걸다',
    },
    SUN: {
      message: '일',
    },
    MON: {
      message: '월',
    },
    TUES: {
      message: '화',
    },
    WED: {
      message: '수',
    },
    THU: {
      message: '목',
    },
    FRI: {
      message: '금',
    },
    SAT: {
      message: '토',
    },
    from: {
      message: "에"
    },
    to: {
      message: "~까지"
    },
    hum: {
      message: '흠',
    },
    nothave: {
      message: "아직 없는 것 같습니다"
    },
    ismenu: {
      message: "저희 메뉴에요..."
    },
    Additional: {
      message: "추가의"
    },
    takeAway: {
      message: "테이크아웃"
    },
  },
};
