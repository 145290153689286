export default {
  translations: {

    language: {
      message: "fr",
    },
    welcomeToOurDigitalMenu: {
      message: "Bienvenue dans notre menu numérique",
    },
    beWelcome: {
      message: "bien viens",
    },
    hello: {
      message: "Bonjour",
    },
    comments: {
      message: "commentaires",
    },
    selectUntil: {
      message: "Sélectionnez jusqu'à",
    },
    optionsYouSelected: {
      message: " options. Vous avez sélectionné",
    },
    youMustAddAllRequiredComplements: {
      message: "Vous devez ajouter tous les compléments requis.",
    },
    youMustQuantityRequired: {
      message: 'Vous devez ajouter au moins 1 produit.',
    },
    cartQuantity: {
      message: "Qté",
    },
    cartItem: {
      message: "Article",
    },
    cartValue: {
      message: "Valeur",
    },
    myOrder: {
      message: "MA COMMANDE",
    },
    emptyCart: {
      message: "PANIER VIDE",
    },
    addMoreItems: {
      message: "AJOUTER PLUS D'ARTICLES",
    },
    subtotal: {
      message: "TOTAL",
    },
    total: {
      message: "TOTAL(E)",
    },
    formOfPayment: {
      message: "FORME DE PAYEMENT",
    },
    deliveryFee: {
      message: "Frais de livraison",
    },
    deliveryIn: {
      message: "Livraison en",
    },
    sendOrder: {
      message: "ENVOYER L'ORDRE",
    },
    ourResponseTimeIsUpTo: {
      message: "Notre temps de réponse est jusqu'à",
    },
    minutes: {
      message: "minutes",
    },
    back: {
      message: "DOS",
    },
    backToMenu: {
      message: "RETOUR AU MENU",
    },
    confirmAddress: {
      message : "CONFIRMER ADRESSE",
    },
    confirmTakeAway: {
      message : "CONFIRMER EMPORTER",
    },
    invalidCoupon: {
      message : "Coupon invalide.",
    },
    discountApplied: {
      message : "Remise appliquée.",
    },
    discount: {
      message : "REDUCTION'",
    },
    youMustAskForChangeForAnAmountGreaterThan: {
      message : "Vous devez demander la monnaie d'un montant supérieur à",
    },
    checkAllFields: {
      message : "Vérifier tous les champs.",
    },
    pleaseSelectAProductBeforeProceeding: {
      message : "Veuillez sélectionner un produit avant de continuer",
    },
    selectAPaymentMethod: {
      message : "Sélectionnez un mode de paiement",
    },
    chooseOneOfTheOptions: {
      message : "Choisissez une des options",
    },
    catalog: {
      message : "Catalogue",
    },
    deliveryTakeAway: {
      message : "Livraison | À Emporter",
    },
    localConsumption: {
      message : "Consommation locale",
    },
    simpleEasyFastDigital: {
      message : "Simple. Facile. Rapide. Numérique.",
    },
    imagesAreForIllustrationPurposesOnly: {
      message : "Les images sont uniquement à des fins d'illustration.",
    },
    changeFor: {
      message: "Change for",
    },
    productImage: {
      message : "Image du produit",
    },
    weAreNotOpenYet: {
      message : "Nous ne sommes pas encore ouverts",
    },
    checkOur: {
      message : "Vérifiez notre",
    },
    openingHours: {
      message : "heures d'ouverture",
    },
    accessMenu: {
      message : "ACCÉDER AU MENU",
    },
    oops: {
      message : "Oups",
    },
    browse: {
      message : "NAVIGUER",
    },
    theHighlights: {
      message : "LES TEMPS FORTS",
    },
    helloHowAreYou: {
      message : "Bonjour, comment allez-vous ?",
    },
    forNowOurMenuCanOnlyBeAccessedOnMobilePhones: {
      message : "Pour l'instant, notre menu n'est accessible que sur les téléphones mobiles.",
    },
    weAreAlreadyWorkingToBringYouTheBestOnlineMenuExperienceAccessibleFromAnyDevice: {
      message : "Nous travaillons déjà pour vous offrir la meilleure expérience de menu en ligne accessible depuis n'importe quel appareil.",
     },
    identification: {
      message : "IDENTIFICATION",
    },
    mandatoryFilling: {
      message : "remplissage obligatoire",
    },
    name: {
      message: "Nom",
    },
    phone: {
      message: "Téléphone",
    },
    cityTakeAway: {
      message: "Ville/A emporter",
    },
    street: {
      message: "Rue",
    },
    neighborhoods: {
      message: "Quartiers",
    },
    number: {
      message: "Nombre",
    },
    complement: {
      message: "Complément",
    },
    reference: {
      message: "Référence",
    },
    coupon: {
      message: "COUPON",
    },
    add: {
      message: "AJOUTER",
    },
    typeWhatYouAreLookingFor: {
      message: "Tapez ce que vous cherchez...",
    },
    goodThatYouAreHere: {
      message: "C'est bien que tu sois là",
    },
    chooseTheItemYouWantAnd: {
      message: "Choisissez l'article que vous voulez et",
    },
    toPlaceYourOrder: {
      message: "pour passer votre commande",
    },
    callAnAttendant: {
      message: "appeler un opérateur ",
    },
    SUN: {
      message: "DIA",
    },
    MON: {
      message: "LUN",
    },
    TUES: {
      message: "MAR",
    },
    WED: {
      message: "MER",
    },
    THU: {
      message: "JEU",
    },
    FRI: {
      message: "VEN",
    },
    SAT: {
      message: "SAM",
    },
    from: {
      message: "DEPUIS"
    },
    to: {
      message: "pour"
    },
    hum: {
      message : "Hummm",
    },
    nothave: {
      message: "On dirait que nous ne l'avons pas encore"
    },
    ismenu: {
      message: "C'est sur notre menu..."
    },
    Additional: {
      message: "Supplémentaire"
    },
    takeAway: {
      message: "emporter"
    },
  },
};
